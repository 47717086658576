<template>
  <div v-if="adkData" :class="styling.wrapperClass">
    <v-subheader v-if="modelItems.length || fieldValue(model)" class="font-weight-bold">{{
      title
    }}</v-subheader>
    <v-list two-line subheader>
      <v-list-item v-if="modelItems.length" style="flex-wrap: wrap">
        <div v-for="(item, index) in modelItems" :key="index + '' + Math.random()">
          <v-btn
            v-if="fieldValue(item.value) != ''"
            id="resourcebutton"
            :href="fieldValue(item.value)"
            :dark="styling.dark"
            :outlined="styling.outlined"
            class="ma-1"
            depressed
            small
            target="_blank"
            >{{ item.name }}</v-btn
          >
        </div>
      </v-list-item>
      <v-list-item v-else style="flex-wrap: wrap">
        <div v-for="(item, index) in fieldValue(model)" :key="index + '' + Math.random()">
          <v-chip-group column>
            <v-chip
              :href="href"
              :target="href ? '_blank' : ''"
              :dark="styling.dark"
              :outlined="styling.outlined"
            >
              {{ item }}
            </v-chip>
          </v-chip-group>
        </div>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api';

export default {
  props: {
    value: {
      type: Object,
      default: () => {}
    },
    adkType: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    items: {
      type: Array,
      default: () => {}
    },
    styling: {
      type: Object,
      default: () => {}
    },
    model: {
      type: String,
      default: ''
    },
    modelItems: {
      type: Array,
      default: () => []
    },
    href: {
      type: String,
      default: ''
    }
  },

  setup(props, ctx) {
    const programDoc = computed({
      get: () => props.value,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });

    const adkData = computed(() => {
      return programDoc.value?.data?.adks?.find(a => a.name === props.adkType);
    });

    const fieldValue = val => {
      return adkData.value?.fields?.find(f => f.model === val)?.value[val] || '';
    };

    return { programDoc, adkData, fieldValue };
  }
};
</script>
