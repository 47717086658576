<template>
  <v-navigation-drawer
    v-model="isDrawer"
    right
    location="right"
    style="width: 400px !important"
    disable-overlay
    persistent
    hide-overlay
  >
    <div
      v-if="
        formItems &&
        formItems.fields &&
        formItems.fields.length > 0 &&
        formItems.name === 'multiselect'
      "
    >
      <v-btn class="close-drawer" @click="hideDrawer">
        <v-icon large>mdi-chevron-double-right</v-icon>
      </v-btn>

      <div v-for="(item, mainIndex) in formItems.fields" :key="mainIndex" class="mt-12">
        <h3
          v-if="item.component_name && item.type && item.type !== 'molecule'"
          class="mt-4 guide-bar__list-item-title"
        >
          {{ item.component_name }}
        </h3>
        <div>
          <div v-for="(field, index) in formProperties" :key="`field` + mainIndex + index">
            <v-text-field
              v-if="item && item.hasOwnProperty(field)"
              v-model="item[field]"
              :label="field"
              :type="field === 'min' || field === 'max' ? 'number' : 'text'"
            ></v-text-field>
          </div>
          <div v-for="(field, index) in stylingProperties" :key="`style` + mainIndex + index">
            <v-text-field
              v-if="
                item.styling && item.styling.hasOwnProperty(field.name) && field.type === 'input'
              "
              v-model="item.styling[field.name]"
              :label="field.name"
            ></v-text-field>
            <v-checkbox
              v-if="
                item.styling && item.styling.hasOwnProperty(field.name) && field.type === 'checkbox'
              "
              v-model="item.styling[field.name]"
              :label="field.name"
            ></v-checkbox>
          </div>
          <div v-for="(field, index) in MOLECULE_PROPERTIES" :key="index">
            <v-text-field
              v-if="checkFieldExists(item, field)"
              v-model="item.props.fields[0][field]"
              :label="field"
            ></v-text-field>
          </div>
          <v-checkbox
            v-if="formData && formData.required && formData.hasOwnProperty('required')"
            v-model="formData.required"
            label="required"
          ></v-checkbox>
        </div>
      </div>
    </div>
    <div v-else class="mt-12">
      <v-btn class="close-drawer" @click="hideDrawer">
        <v-icon large>mdi-chevron-double-right</v-icon>
      </v-btn>

      <div v-if="type !== 'icon' && type !== 'title'" class="mt-12">
        <div v-for="field in formProperties" :key="field">
          <v-text-field
            v-if="formData && formData.hasOwnProperty(field)"
            v-model="formData[field]"
            :label="field"
          ></v-text-field>
        </div>
        <div v-for="field in stylingProperties" :key="field.name">
          <v-text-field
            v-if="styling && styling.hasOwnProperty(field.name) && field.type === 'input'"
            v-model="styling[field.name]"
            :label="field.name"
          ></v-text-field>
          <v-checkbox
            v-if="styling && styling.hasOwnProperty(field.name) && field.type === 'checkbox'"
            v-model="styling[field.name]"
            :label="field.name"
          ></v-checkbox>
        </div>
        <div v-if="formData && formData.hasOwnProperty('groupLabel')">
          <v-text-field
            v-model="formData.groupLabel"
            class="text-field-left"
            placeholder="Group label"
            label="label"
          ></v-text-field>
          <span style="font-size: 20px; margin-left: 8px; font-weight: bold">Options</span>
          <div v-for="(radio, index) in formData.radioButtons" :key="index" class="radio-row">
            <v-text-field
              v-model="radio.value"
              class="text-field-left"
              placeholder="Value"
            ></v-text-field>

            <div class="arrow-container">
              <v-icon size="24">mdi-arrow-right</v-icon>
            </div>

            <v-text-field
              v-model="radio.label"
              class="text-field-right"
              placeholder="Label"
            ></v-text-field>
          </div>
        </div>
        <validation-observer ref="validation" v-slot="{ invalid }">
          <div
            v-if="formData && (formData.type === 'AComboBox' || formData.type == 'AVSelect')"
            style="text-align: right"
          >
            <validation-provider v-slot="{ errors }" rules="required" tag="div">
              <v-text-field
                v-model="comboBoxValue"
                :error-messages="errors"
                label="Enter select options"
              ></v-text-field>
            </validation-provider>
            <v-btn :disabled="invalid" class="custom-btn" @click="changeCombobox">Add</v-btn>
          </div>
        </validation-observer>
        <div v-if="formData && formData.hasOwnProperty('items')" class="form-chip">
          <v-chip
            v-for="(item, index) in formData.items"
            :key="index"
            closable
            variant="text"
            @click="selectedItem(item)"
            @click:close="chip = false"
          >
            {{ item }}
          </v-chip>
        </div>
        <v-dialog
          v-if="formData && formData.hasOwnProperty('minDate')"
          ref="dialog"
          v-model="minModal"
          :return-value.sync="minDate"
          persistent
          width="290px"
        >
          <template #activator="{ on }">
            <v-text-field v-model="formData.minDate" label="Min Date" v-on="on"></v-text-field>
          </template>
          <v-date-picker v-model="formData.minDate" no-title scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="minModal = false">Cancel</v-btn>
            <v-btn text color="primary" @click="minModal = false"> OK </v-btn>
          </v-date-picker>
        </v-dialog>
        <v-dialog
          v-if="formData && formData.hasOwnProperty('maxDate')"
          ref="dialog"
          v-model="maxModal"
          :return-value.sync="maxDate"
          persistent
          width="290px"
        >
          <template #activator="{ on }">
            <v-text-field v-model="formData.maxDate" label="Max Date" v-on="on"></v-text-field>
          </template>
          <v-date-picker v-model="formData.maxDate" no-title scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="maxModal = false">Cancel</v-btn>
            <v-btn text color="primary" @click="maxModal = false"> OK </v-btn>
          </v-date-picker>
        </v-dialog>
        <div v-if="formData && formData.type === 'ARadioGroup'">
          <span style="font-size: 20px; margin-left: 8px; font-weight: bold">Add More options</span>
          <div style="display: flex; align-items: center; text-align: right">
            <v-text-field
              v-model="radioValue"
              class="text-field-left"
              placeholder="value"
            ></v-text-field>
            <v-text-field
              v-model="radioLabel"
              class="text-field-left"
              placeholder="label"
            ></v-text-field>
            <v-btn class="custom-btn" @click="addRadioValue"> Button </v-btn>
          </div>
        </div>
        <div v-if="formData && formData.hasOwnProperty('radioButtons')" class="form-chip">
          <v-chip
            v-for="(item, index) in formData.radioButtons"
            :key="index"
            closable
            variant="text"
            @click="selectedRadio(index)"
            @click:close="chip = false"
          >
            {{ item.label }}
          </v-chip>
        </div>
        <div v-if="formData && formData.type === 'ACheckBoxGroup'">
          <span style="font-size: 20px; margin-left: 8px; font-weight: bold">Add More options</span>
          <div style="display: flex; align-items: center; text-align: right">
            <v-text-field
              v-model="checkBoxValue"
              class="text-field-left"
              placeholder="value"
            ></v-text-field>
            <v-text-field
              v-model="checkBoxLabel"
              class="text-field-left"
              placeholder="label"
            ></v-text-field>
            <v-btn class="custom-btn" @click="addCheckBoxValue"> Button </v-btn>
          </div>
        </div>
        <div v-if="formData && formData.hasOwnProperty('checkboxItems')" class="form-chip">
          <v-chip
            v-for="(item, index) in formData.checkboxItems"
            :key="index"
            closable
            variant="text"
            @click="selectedCheckbox(index)"
            @click:close="chip = false"
          >
            {{ item.label }}
          </v-chip>
        </div>
        <div v-for="(field, label) in moleculesProps" :key="field">
          <v-text-field
            v-if="fieldStyleProp && fieldStyleProp.hasOwnProperty(label)"
            v-model="fieldStyleProp[label]"
            :label="field"
          />
        </div>
        <v-select
          v-if="preSelectItems && preSelectItems.length > 1"
          v-model="selectedValue"
          style="margin-top: 24px"
          label="Default Selected"
          :items="preSelectItems"
        ></v-select>
        <div
          v-if="
            formData &&
            formData.component_name === 'ACheckBox' &&
            formData.hasOwnProperty('defaultValue')
          "
          style="
            position: relative;
            display: flex;
            align-items: center;
            margin-top: 10px;
            margin-bottom: 23px;
          "
        >
          <span>Selected by default</span>
          <v-switch
            v-model="formData.defaultValue"
            style="position: absolute; right: 0"
            color="purple"
            inset
          />
        </div>
        <div v-if="formData && formData.comp_type === 'atom'">
          <div class="mt-2 mb-2">Specify who can interact with this field</div>
          <v-checkbox v-model="formData.roles" label="employer" value="employer"></v-checkbox>
          <v-checkbox v-model="formData.roles" label="participant" value="participant"></v-checkbox>
        </div>
      </div>
      <v-text-field
        v-if="type === 'title'"
        v-model="adkTitle"
        :disabled="adkTitle.toLowerCase() === 'start' || adkTitle.toLowerCase() === 'results'"
        label="Activity Title"
      ></v-text-field>
      <div v-if="type === 'icon'" style="display: flex; flex-direction: column; gap: 10px">
        <div>
          <h4>Select Activity Icon</h4>
        </div>
        <v-divider></v-divider>
        <div style="display: flex; gap: 10px; flex-wrap: wrap">
          <div
            v-for="(icon, index) in adkIcons"
            :key="icon"
            style="cursor: pointer"
            @click="iconSelected(icon, index)"
          >
            <v-icon :color="index === iconIndex ? 'rgba(255, 0, 0, 0.5)' : ''" size="50" light>{{
              icon
            }}</v-icon>
          </div>
        </div>
      </div>
      <div v-if="formData && formData.rules && formData.comp_type === 'atom'">
        <div class="mt-2 mb-2">Field is required or not</div>
        <v-checkbox
          v-model="isRequired"
          label="required"
          :value="isRequired"
          @change="changeRules"
        ></v-checkbox>
      </div>
    </div>
  </v-navigation-drawer>
</template>

<script>
import { ref, defineComponent, watch, computed, set } from '@vue/composition-api';
import { useFormsState } from '@/store';
import {
  STYLING_PROPERTIES,
  FORM_PROPERTIES,
  moleculesVars,
  adkIcons,
  MOLECULE_PROPERTIES
} from '@/constants/form-builder';
import moment from 'moment';

export default defineComponent({
  name: 'Drawer',
  props: {
    drawer: {
      required: true,
      type: Boolean,
      default: false
    },
    type: {
      required: false,
      type: String,
      default: ''
    },
    title: {
      required: false,
      type: String,
      default: ''
    }
  },
  setup(props, ctx) {
    const { forms } = useFormsState(['forms']);
    const formsValues = ref(forms);
    const maxModal = ref('');
    const minModal = ref('');
    const isDrawer = ref(props.drawer);
    const selectedChip = ref(0);
    const formData = ref([]);
    const styling = ref({});
    const comboBoxValue = ref('');
    const radioValue = ref('');
    const radioLabel = ref('');
    const checkBoxValue = ref('');
    const checkBoxLabel = ref('');
    const minDate = ref('');
    const maxDate = ref('');
    const selectedValue = ref('');
    const moleculesProps = ref(moleculesVars);
    const roles = ref([]);
    const adkTitle = ref(props.title);
    const iconIndex = ref('');
    const formItems = ref();
    const moleculeFields = ref([]);
    const fieldStyleProp = ref({});
    const listedRadioValues = computed({
      get: () => formData.value?.radioButtons
    });
    let radioButtonArray;
    const preSelectItems = computed(() => {
      if (listedRadioValues.value?.length > 0) {
        radioButtonArray = listedRadioValues.value.map(elem => {
          const radioObj = {
            text: elem.label,
            value: elem.value
          };
          return radioObj;
        });
        return radioButtonArray;
      }
      return false;
    });

    const isRequired = computed({
      get: () => formData.value?.rules?.includes?.('required'),
      set: () => formData.value?.rules?.includes?.('required')
    });

    const checkFieldExists = (item, field_name) => {
      const field = item?.props?.fields[0];
      if (field?.[field_name]) {
        return true;
      }
      return false;
    };

    const updateRadioValues = () => {};

    const changeCombobox = () => {
      if (comboBoxValue.value) {
        formData.value.items.push(comboBoxValue.value);
        comboBoxValue.value = '';
      }
    };

    const addRadioValue = () => {
      if (radioValue.value && radioLabel.value) {
        formData.value.radioButtons.push({
          label: radioLabel.value,
          value: radioValue.value
        });
        radioValue.value = '';
        radioLabel.value = '';
      }
    };

    const addCheckBoxValue = () => {
      if (checkBoxValue.value && checkBoxLabel.value) {
        formData.value.checkboxItems.push({
          label: checkBoxLabel.value,
          value: checkBoxValue.value
        });
        checkBoxValue.value = '';
        checkBoxLabel.value = '';
      }
    };

    watch(adkTitle, (currentValue, oldVal) => {
      ctx.emit('emitTitle', currentValue);
    });

    watch(
      () => props.drawer,
      isDrawerOpen => {
        isDrawer.value = isDrawerOpen;
      }
    );

    const selectedItem = item => {
      const index = formData.value.items.indexOf(item);
      formData.value.items.splice(index, 1);
    };

    const selectedRadio = index => {
      if (formData.value.radioButtons.length > 2) formData.value.radioButtons.splice(index, 1);
    };

    const selectedCheckbox = index => {
      if (formData.value.checkboxItems.length > 2) formData.value.checkboxItems.splice(index, 1);
    };

    const fetchFields = (name, fields) => {
      return fields?.find(item => item.name === name)?.styling;
    };

    watch(formsValues, (currentValue, oldValue) => {
      formItems.value = {};
      formData.value = {};
      fieldStyleProp.value = {};
      if (forms.value.isOpen) {
        if (currentValue?.element?.name === 'multiselect') {
          formItems.value = currentValue?.element;
        } else {
          formItems.value = {};
          fieldStyleProp.value = {};
          formData.value = forms.value?.element;
          if (formData.value && formData?.value?.props && formData?.value?.props?.fields) {
            const { fields } = formData.value.props;
            const { component_name: name } = formData.value;
            if (fields) {
              const fieldStyling = fetchFields(name, fields);
              if (fieldStyling) {
                fieldStyleProp.value = fieldStyling;
              } else {
                fieldStyleProp.value = {};
              }
            }
          }
          styling.value = formData.value?.styling || formData.value?.props?.styling;
          if (currentValue?.element?.component_name === 'ADatePicker') {
            formData.value.maxDate = moment().format('YYYY-MM-DD');
            formData.value.defaultValue = moment().format('YYYY-MM-DD');
          }
        }
        isDrawer.value = true;
      } else {
        isDrawer.value = false;
      }
    });

    const iconSelected = (icon, index) => {
      iconIndex.value = index;
      ctx.emit('emitType', icon);
    };

    const hideDrawer = () => {
      isDrawer.value = false;
      ctx.emit('close');
    };
    watch(selectedValue, new_value => {
      formData.value.defaultValue = new_value;
    });
    const changeRules = val => {
      if (formData?.value?.rules?.includes?.('required')) {
        const index = formData?.value?.rules.findIndex(element => element === 'required');
        if (index !== -1) {
          formData.value.rules.splice(index, 1);
        }
      } else {
        formData.value?.rules?.push('required');
      }
    };
    return {
      iconIndex,
      roles,
      moleculesProps,
      fieldStyleProp,
      stylingProperties: STYLING_PROPERTIES,
      formProperties: FORM_PROPERTIES,
      adkIcons,
      selectedValue,
      preSelectItems,
      minDate,
      maxDate,
      selectedRadio,
      selectedCheckbox,
      addRadioValue,
      radioValue,
      minModal,
      maxModal,
      selectedItem,
      changeCombobox,
      comboBoxValue,
      formData,
      styling,
      isDrawer,
      selectedChip,
      iconSelected,
      adkTitle,
      hideDrawer,
      formItems,
      changeRules,
      isRequired,
      moleculeFields,
      checkFieldExists,
      MOLECULE_PROPERTIES,
      radioLabel,
      checkBoxValue,
      checkBoxLabel,
      addCheckBoxValue
    };
  }
});
</script>

<style lang="scss">
.form-box {
  padding: 30px !important;
}
.card-style {
  height: 100%;
}

.form-chip {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.v-navigation-drawer {
  &__content {
    position: relative;
    padding: 30px 30px;
    .v-btn.close-drawer {
      position: absolute;
      top: 25px;
      // right: 10px;
      left: 25px;
      background: transparent;
      box-shadow: none;
      // border: 1px solid;
      border-radius: 100%;
      padding: 0;
      height: 30px;
      width: 30px;
      min-width: auto;
      i {
        font-size: 16px;
      }
    }
    .v-input {
      // padding: 0;
      // margin: 0;
      margin: 10px;
      &.v-input--checkbox {
        margin: 0;
        padding: 0;
        .v-input__slot {
          margin: 10px 0;
        }
      }
      &__control {
        .v-messages {
          min-height: 1px;
        }
      }
      .v-text-field__details {
        min-height: 1px;
      }
      .v-label {
        height: auto;
        text-transform: capitalize;
        font-size: 18px;
        line-height: 22px;
        top: 0;
      }
    }
  }
}
.custom-btn {
  background: transparent !important;
  border: 1px solid #000;
  box-shadow: none;
  color: #000;
  border-radius: 25px;
}

.row {
  display: flex;
  align-items: center;
}

.text-field-left {
  flex: 1;
  margin-right: 10px;
}

.text-field-right {
  flex: 1;
  margin-left: 10px;
}

.radio-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
</style>
