var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',[_c('v-container',{staticClass:"module-default pa-0 align-center mb-16"},[_c('v-form',{staticClass:"mt-12 mx-3",model:{value:(_vm.checkoutFormCompleted),callback:function ($$v) {_vm.checkoutFormCompleted=$$v},expression:"checkoutFormCompleted"}},[_c('span',{staticClass:"module-default__question-title mt-12 font-weight-bold"},[_vm._v(" Are you open to winning unpaid or paid work experiences? ")]),_c('v-radio-group',{attrs:{"rules":[_vm.rules.required],"hide-details":""},model:{value:(_vm.fieldValue.rewards),callback:function ($$v) {_vm.$set(_vm.fieldValue, "rewards", $$v)},expression:"fieldValue.rewards"}},[(
            (_vm.rewardSetup && _vm.rewardSetup.length === 0) || (_vm.rewardSetup && _vm.rewardSetup.length === 2)
          )?_c('v-radio',{attrs:{"label":"Yes","value":"yes"}}):_vm._e(),(
            _vm.rewardSetup && _vm.rewardSetup[0] === 'Paid Work Experience' && _vm.rewardSetup.length === 1
          )?_c('v-radio',{attrs:{"label":"Yes","value":"yes"}}):_vm._e(),(
            _vm.rewardSetup && _vm.rewardSetup[0] === 'Unpaid Work Experience' && _vm.rewardSetup.length === 1
          )?_c('v-radio',{attrs:{"value":"yes"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v(" Yes "),_c('strong',{staticClass:"module-default__required ml-2"},[_vm._v(" REQUIRED")])])]},proxy:true}],null,false,1654342460)}):_vm._e(),(
            _vm.rewardSetup && _vm.rewardSetup[0] === 'Paid Work Experience' && _vm.rewardSetup.length === 1
          )?_c('v-radio',{attrs:{"value":"paid only"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v(" Paid Only "),_c('strong',{staticClass:"module-default__required ml-2"})])]},proxy:true}],null,false,2932718000)}):(
            _vm.rewardSetup && _vm.rewardSetup[0] === 'Unpaid Work Experience' && _vm.rewardSetup.length === 1
          )?_c('v-radio',{attrs:{"label":"Paid Only","value":"paid only","disabled":""}}):_c('v-radio',{attrs:{"value":"paid only"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v("Paid Only")])]},proxy:true}])})],1),(_vm.isExternShip)?_c('div',{staticClass:"mt-8"},[_c('v-form',{model:{value:(_vm.externShipCheckout),callback:function ($$v) {_vm.externShipCheckout=$$v},expression:"externShipCheckout"}},[_c('span',{staticClass:"module-default__question-title font-weight-bold"},[_vm._v("It's just externship program, Are you sure ? ")]),_c('v-radio-group',{attrs:{"rules":[_vm.rules.required],"hide-details":""},model:{value:(_vm.fieldValue.externShip),callback:function ($$v) {_vm.$set(_vm.fieldValue, "externShip", $$v)},expression:"fieldValue.externShip"}},[_c('v-radio',{attrs:{"label":"Yes","value":"yes"}})],1)],1)],1):_vm._e(),(_vm.skillsSetup && _vm.skillsSetup.length !== 0)?_c('div',{staticClass:"mt-8"},[_c('span',{staticClass:"module-default__question-title font-weight-bold"},[_vm._v("Do you know, can learn or have access to the following items? ")]),_vm._l((_vm.skillsSetup),function(requiredSkills,itemIndex){return _c('div',{key:itemIndex},[_c('validation-provider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('v-checkbox',{attrs:{"value":requiredSkills,"rules":[_vm.rules.allSkillsSelected],"hide-details":"","label":requiredSkills,"error-messages":errors,"multiple":"","required":""},model:{value:(_vm.fieldValue.accessSkills),callback:function ($$v) {_vm.$set(_vm.fieldValue, "accessSkills", $$v)},expression:"fieldValue.accessSkills"}},[_vm._v(" "+_vm._s(requiredSkills)+" ")])]}}],null,true)})],1)})],2):_vm._e(),(_vm.residencySetup && _vm.residencySetup.length !== 0)?_c('div',{staticClass:"mt-8"},[_c('span',{staticClass:"module-default__question-title font-weight-bold"},[_vm._v("Do you live or work in any of the priority jurisdictions? ")]),_c('v-radio-group',{attrs:{"rules":[_vm.rules.required],"hide-details":""},model:{value:(_vm.fieldValue.studentLocation),callback:function ($$v) {_vm.$set(_vm.fieldValue, "studentLocation", $$v)},expression:"fieldValue.studentLocation"}},[_vm._l((_vm.residencySetup),function(requiredResidency,itemIndex){return _c('v-radio',{key:itemIndex,attrs:{"label":requiredResidency,"value":requiredResidency}})}),_c('v-radio',{attrs:{"label":"None of the above"}})],2)],1):_vm._e(),_c('m-use-token',{ref:"useToken",attrs:{"fetch-program":_vm.fetchProgram,"update-adk":_vm.updateAdk},on:{"showFeedBackForm":function($event){return _vm.$emit('showFeedBackForm')},"next-page":function($event){return _vm.$emit('next-page')}},model:{value:(_vm.programDoc),callback:function ($$v) {_vm.programDoc=$$v},expression:"programDoc"}})],1),(_vm.checkoutQualificationErrors && _vm.checkoutQualificationErrors.length >= 1)?_c('div',{staticClass:"mt-8"},[_c('h4',{staticClass:"text-center"},[_vm._v(" You must fulfill the following checkout qualifications to proceed: ")]),_c('ul',_vm._l((_vm.checkoutQualificationErrors),function(item,index){return _c('li',{key:index},[_c('span',{staticClass:"text-danger",staticStyle:{"color":"red"}},[_vm._v(_vm._s(item))])])}),0)]):_vm._e(),_c('a-btn',{attrs:{"align":"center","loading":_vm.isLoading,"styling":{
        label: 'Start Program',
        color: 'black',
        wrapperClass: 'mt-8',
        class: 'white--text font-weight-bold',
        outlined: false,
        rounded: true,
        xLarge: true,
        depressed: false
      }},on:{"click":_vm.openCheckoutDialog}}),_c('div',{staticClass:"mt-3 d-flex justify-center"},[(
          _vm.programDoc.data.tokens !== undefined &&
          _vm.programDoc.data.tokens !== null &&
          _vm.programDoc.data.tokens === 0
        )?_c('h5',{staticClass:"d-flex module-default__make-italic"},[_vm._v(" This is a free program, checkout without token. ")]):_c('h5',{staticClass:"d-flex module-default__make-italic"},[_vm._v("No tokens? Join unsponsored.")])]),_c('v-tooltip',{attrs:{"bottom":"","color":"black","content-class":"white--text","open-delay":300},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"white--text mr-2 ml-2 mt-2 mb-2",attrs:{"color":"black","elevation":"0","fab":"","x-small":""},on:{"click":function($event){_vm.tokenDescDialog = true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-help-circle")])],1)]}}])},[_c('span',[_vm._v("Tokens are used to sponsor a participant for a program.")])]),_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.tokenDescDialog),callback:function ($$v) {_vm.tokenDescDialog=$$v},expression:"tokenDescDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 grey lighten-2"},[_vm._v("What is a Token?")]),_c('v-card-text',[_c('br'),_vm._v(" Tokens are used to sponsor a participant for a program. You can purchase a token for yourself or someone else by clicking below. ")]),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.tokenDescDialog = false}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-close")]),_vm._v(" Dismiss ")],1),_c('v-btn',{attrs:{"color":"blue","text":""},on:{"click":_vm.redirectToPayment}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-arrow-right")]),_vm._v(" Go to Payment ")],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }