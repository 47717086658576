









































































































































import { defineComponent, ref, computed, onMounted } from '@vue/composition-api';
import { ValidationObserver, ValidationProvider } from '@/validation';
import { useUserActions } from '@/store';
import { ObjectId } from 'bson';
import axios from 'axios';
import MongoDoc from './types';
import Table from './TableView.vue';

export default defineComponent({
  name: 'ModulePresets',
  components: {
    ValidationProvider,
    ValidationObserver,
    Table
  },
  props: {
    value: {
      required: true,
      type: Object as () => MongoDoc,
      default: () => {}
    },
    adkType: {
      type: String,
      default: ''
    },
    userType: {
      required: false,
      type: String,
      default: ''
    },
    mode: {
      type: String,
      default: ''
    },
    adkIndex: {
      type: Number,
      default: 0
    }
  },
  setup(props, ctx: any) {
    const { updateProgramState } = useUserActions(['updateProgramState']);
    const programDocument = computed({
      get: () => props.value,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });

    const adk_index = computed(() => {
      return props.adkIndex;
    });

    const adkIdValue: any = computed(() => {
      return programDocument?.value?.data?.adks[props.adkIndex]?.id;
    });

    const index = programDocument.value?.data?.adks?.findIndex(obj => {
      if (obj) {
        return obj.name === props.adkType;
      }
      return false;
    });

    const isFormShow = ref(false);

    const obj = ref({
      contactMethod: [],
      contactPreferences: [],
      hideEmail: false,
      hidePhone: false,
      schedulingLink: '',
      userId: ''
    });
    const isSaving = ref(false);
    const validate = ref();

    const list = computed({
      get: () => obj.value,
      set: newVal => {
        obj.value = newVal;
      }
    });

    const overlay = ref(false);
    const isAbsolute = ref(true);

    const currentUser = ref();
    const isDataAvailable = ref(false);
    const userTypeValue = computed({
      get: () => props.userType,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });
    const programIdValue: any = computed(() => {
      if (userTypeValue.value === 'participant') {
        return programDocument?.value?.data?.program_id?.toString();
      }
      return programDocument?.value?.data._id?.toString();
    });

    async function saveDirectoryData() {
      isSaving.value = true;
      if (currentUser.value) {
        obj.value.userId = currentUser.value.user_id;
      }

      const API_ENDPOINT = process.env.VUE_APP_ADK_DIRECTORY_CREATE_USER;
      const id = ctx.root._route.params.programId || programIdValue?.value;

      const data = {
        user_id: obj.value.userId,
        program_id: id,
        contact_method: obj.value.contactMethod,
        contact_preferences: obj.value.contactPreferences,
        hide_email: obj.value.hideEmail,
        hide_phone: obj.value.hidePhone,
        scheduling_link: obj.value.schedulingLink,
        adkId: adkIdValue?.value
      };

      axios
        .post(`${API_ENDPOINT}`, data, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
          }
        })
        .then(res => {
          isSaving.value = false;
          isDataAvailable.value = true;
          updateProgramState(res.data?.data?.document);
          ctx.emit('update-doc');
        })
        .catch(error => {
          isSaving.value = false;
        });
    }

    async function updateData() {
      const API_ENDPOINT: any = process.env.VUE_APP_ADK_DIRECTORY_CREATE_USER;
      isSaving.value = true;
      const data = {
        user_id: currentUser.value.user_id,
        program_id: ctx.root._route.params.programId || programIdValue?.value,
        contact_method: obj.value.contactMethod,
        contact_preferences: obj.value.contactPreferences,
        hide_email: obj.value.hideEmail,
        hide_phone: obj.value.hidePhone,
        scheduling_link: obj.value.schedulingLink,
        adkId: adkIdValue?.value
      };
      axios
        .patch(API_ENDPOINT, data, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
          }
        })
        .then(res => {
          isSaving.value = false;
        })
        .catch(error => {
          console.log(error);
          isSaving.value = false;
        });
    }

    const isLoading = ref(false);
    const isActive = ref(0);
    const color = ref('');

    function getCurrentUser(val, index) {
      obj.value = {
        contactMethod: [],
        contactPreferences: [],
        hideEmail: false,
        hidePhone: false,
        schedulingLink: '',
        userId: ''
      };
      validate.value.reset();
      isActive.value = index + 1;
      currentUser.value = val;

      const current_user_id = currentUser.value.user_id;
      const API_ENDPOINT = process.env.VUE_APP_ADK_DIRECTORY_CREATE_USER;
      const id = ctx.root._route.params.programId || programIdValue?.value;
      overlay.value = true;
      isLoading.value = true;
      axios
        .get(
          `${API_ENDPOINT}?program_id=${id}&user_id=${current_user_id}&adkId=${adkIdValue?.value}`,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
            }
          }
        )
        .then(res => {
          if (Object.keys(res.data).length !== 0) {
            const payld = res.data[0];
            if (currentUser.value.user_id === payld.userId) isDataAvailable.value = true;
            obj.value = payld;
            isLoading.value = false;
            overlay.value = false;
          } else {
            isDataAvailable.value = false;
            isLoading.value = false;
            overlay.value = false;
          }
        })
        .catch(error => console.log(error));
    }

    const infoData = ref();
    const getData = () => {
      const API_ENDPOINT = process.env.VUE_APP_ADK_DIRECTORY_GET_USER;
      const id = ctx.root._route.params.programId || programIdValue?.value;
      axios
        .get(`${API_ENDPOINT}?program_id=${id}&employer_only=${true}&adkId=${adkIdValue?.value}`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
          }
        })
        .then(res => {
          infoData.value = res.data;
          getCurrentUser(infoData.value[0], isActive.value);
        })
        .catch(error => console.log(error));
    };
    onMounted(() => {
      if (props?.mode !== 'build') {
        getData();
      }
    });

    return {
      programDocument,
      index,
      saveDirectoryData,
      list,
      getCurrentUser,
      infoData,
      isLoading,
      isAbsolute,
      overlay,
      isActive,
      color,
      isSaving,
      isDataAvailable,
      updateData,
      validate,
      isFormShow,
      programIdValue,
      adkIdValue
    };
  }
});
