export const STYLING_PROPERTIES = [
  {
    name: 'label',
    type: 'input'
  },
  {
    name: 'headerLabel',
    type: 'input'
  },
  {
    name: 'placeholder',
    type: 'input'
  },
  {
    name: 'rows',
    type: 'input'
  },
  {
    name: 'height',
    type: 'input'
  },
  {
    name: 'width',
    type: 'input'
  },
  {
    name: 'backgroundColor',
    type: 'input'
  },
  {
    name: 'btnLabel',
    type: 'input'
  },
  {
    name: 'class',
    type: 'input'
  },
  {
    name: 'hint',
    type: 'input'
  },
  {
    name: 'outlined',
    type: 'checkbox'
  },
  {
    name: 'rounded',
    type: 'checkbox'
  },
  {
    name: 'xLarge',
    type: 'checkbox'
  },
  {
    name: 'xSmall',
    type: 'checkbox'
  },
  {
    name: 'dense',
    type: 'checkbox'
  },
  {
    name: 'depressed',
    type: 'checkbox'
  },
  {
    name: 'toggle',
    type: 'checkbox'
  },
  {
    name: 'fullWidth',
    type: 'checkbox'
  },
  {
    name: 'hideDetails',
    type: 'checkbox'
  },
  {
    name: 'clearable',
    type: 'checkbox'
  }
];

export const FORM_PROPERTIES = ['title', 'ticks', 'min', 'max', 'label'];

export const MOLECULE_PROPERTIES = ['linkLabel', 'videoName', 'addButtonLabel'];

export const EXCLUDE_PROPERTIES = [
  'draggable_name',
  'draggable_icon',
  'comp_type',
  'component_name',
  'disabled',
  'persistentPlaceholder',
  'persistentHint',
  'mode',
  'disabledInput',
  'userType'
];

export const PROGRAM_DEFAULT_PROPERTIES = {
  icon: 'mdi-rocket-outline',
  status: true,
  setupRequired: true,
  permissions: [
    {
      roles: 'student',
      permissions_matrix: ['can_see', 'can_add', 'can_modify']
    },
    { roles: 'participant', permissions_matrix: ['can_see'] }
  ],
  presetFields: [
    {
      roles: ['employer'],
      type: 'noTweaks',
      title: 'No tweaking necessary',
      noSetup: true,
      position: 0
    }
  ],
  styling: { borderColor: '#000000' },
  preview: false
};

export const PREDEFINED_EDIT_FIELDS = [
  {
    name: 'm-discord',
    styling: {
      title: 'Join the PilotCity online student',
      createAccountLabel: 'Create Discord Account',
      existingAccountLabel: 'Connect existing account'
    }
  },
  {
    name: 'm-videoask-generation',
    styling: {
      generate_btn_label: 'Generate Interview',
      videoask_form_id: process.env.VUE_APP_VIDEOASK_FORM_ID
    }
  },
  {
    name: 'metagrabber',
    styling: {
      generate_btn_label: 'Generate Interview'
    }
  },
  {
    name: 'm-meet-adk',
    styling: {
      meetHeader: 'Connect Google or Outlook email associated with PilotCity account'
    }
  },
  {
    name: 'm-welcome-adk',
    styling: {
      headerLabel: 'Insert get started folder (Recommended)',
      label: 'Enter link to shared drive (ie: Google Drive)',
      hint: 'Please make sure it is editable by anyone who views',
      btnLabel: 'Verify & Save Link'
    }
  },
  {
    name: 'm-matterport',
    styling: {
      headerLabel: 'Add Matterport ID to render embeded 3D view',
      label: 'Enter your matterport space ID',
      hint: 'Please make sure it is editable by anyone who views',
      btnLabel: 'ADD'
    }
  }
];

export const noSetup = {
  roles: ['employer'],
  type: 'noSetup',
  title: 'No setup necessary',
  noSetup: true,
  position: 0
};

export const noEditMolecules = ['m-videoask', 'MContactAdk', 'm-deliverable-adk'];

export const moleculesVars = {
  headerLabel: 'Header label',
  label: 'Label',
  hint: 'Hint',
  btnLabel: 'Button Label',
  generate_btn_label: 'Generate button label',
  videoask_form_id: 'Videoask form id',
  meetHeader: 'Meet header',
  title: 'Title',
  createAccountLabel: 'Create account label',
  existingAccountLabel: 'Existing account label'
};

export const adkIcons = [
  'mdi-rocket-outline',
  'mdi-book-open',
  'mdi-discord',
  'mdi-account-supervisor-circle',
  'mdi-teach',
  'mdi-bookmark-multiple',
  'mdi-timetable',
  'mdi-head-flash-outline',
  'mdi-elevator-passenger',
  'mdi-comment-text-multiple-outline',
  'mdi-feather',
  'mdi-youtube',
  'mdi-account-supervisor-circle-outline',
  'mdi-file-account',
  'mdi-star'
];

export const disableDragOnceDropped = ['AButton'];

export const staticButtons = {
  draggable_icon: 'https://cdn-icons-png.flaticon.com/512/2476/2476980.png',
  draggable_name: 'save adk only',
  name: 'm-save-adk-only',
  comp_type: 'molecule',
  component_name: 'MSaveAdkOnly',
  roles: ['employer', 'participant'],
  type: 'molecule'
};

export const multiSelectMolecule = ['train', 'research'];
