<template>
  <div class="vuedraggable-main">
    <div v-if="!isLoading" class="vue-form-row">
      <div class="column-1 col px-2 bg-light border rounded">
        <v-stepper vertical class="guide-bar__stepper main_timeline_bar ma-0 pa-0">
          <v-stepper-step
            class="category guide-bar__timeline-category"
            color="black"
            :complete-icon="'mdi-blur'"
            :edit-icon="'$radioOff'"
            :complete="true"
            :editable="false"
            :step="0"
            @click="toggleCategory('pre')"
            ><h2>Make Activity</h2></v-stepper-step
          >
          <draggable
            class="inner"
            :clone="clone"
            :list="fields"
            :options="{ handle: '.categoryDragged' }"
            :group="{ name: 'forms', pull: 'clone', put: false }"
            @end="itemDropped"
          >
            <div
              v-for="(field, index) in fields"
              :key="index"
              :class="
                !addedItems.includes(field.component_name) ? 'categoryDragged' : 'disable-dragged'
              "
            >
              <div class="draggable_div d-flex">
                <img style="height: 25px; width: 25px" :src="field.draggable_icon || field.icon" />
                <p class="font-weight-bold comp-name">
                  {{ field.draggable_name }}
                </p>
              </div>
            </div>
          </draggable>
        </v-stepper>
        <div class="text-center pa-0 ma-0">
          <validation-observer ref="validation" v-slot="{ invalid }">
            <v-btn
              :disabled="formsFields.length <= 0"
              color="primary"
              rounded
              class="font-weight-bold"
              depressed
              x-large
              @click="dialog = true"
            >
              Save Activity
              <v-dialog
                v-model="dialog"
                transition="dialog-top-transition"
                width="500"
                persistent
                activator="parent"
              >
                <v-card style="padding: 25px">
                  <div class="ml-auto">
                    <v-btn
                      style="top: 10px; right: 10px"
                      class="dialog-close"
                      large
                      icon
                      color="primary"
                      @click="dialog = false"
                    >
                      <v-icon> mdi-close </v-icon>
                    </v-btn>
                  </div>
                  <validation-provider v-slot="{ errors }" rules="required" tag="div">
                    <v-text-field
                      v-if="adkName !== 'start' || adkName !== 'results'"
                      v-model="adkName"
                      :error-messages="errors"
                      label="Enter Activity Name"
                      :disabled="adkName === 'start' || adkName === 'results'"
                      required
                      x-large
                      rounded
                      outlined
                      class="mt-6"
                    ></v-text-field
                  ></validation-provider>
                  <validation-provider v-slot="{ errors }" rules="required">
                    <v-select
                      v-model="selectedCategory"
                      :items="categories"
                      :error-messages="errors"
                      label="Select Category"
                      outlined
                      rounded
                      :disabled="adkName === 'start' || adkName === 'results'"
                      class="colorSelect"
                    ></v-select>
                  </validation-provider>
                  <validation-provider v-slot="{ errors }" rules="required">
                    <v-select
                      v-model="selectedColor"
                      :items="colors.map(c => c.value)"
                      :error-messages="errors"
                      label="Select Category"
                      outlined
                      rounded
                      class="colorSelect"
                    ></v-select>
                  </validation-provider>
                  <h4 align="center" class="mt-2">Instruction</h4>
                  <v-text-field
                    v-model="goal"
                    label="Enter Goal"
                    required
                    x-large
                    rounded
                    outlined
                    class="mt-6"
                  ></v-text-field>
                  <v-combobox
                    v-model="boilerInstructions"
                    :items="boilerInstructionsOptions"
                    :search-input="boilerInstructionsSearch"
                    rounded
                    label="Add boiler instructions"
                    multiple
                    small-chips
                    hide-details
                    outlined
                  >
                    <template #selection="{ attrs, item, parent, selected }">
                      <v-chip
                        v-bind="attrs"
                        :input-value="selected"
                        label
                        small
                        @click="parent.selectItem(item)"
                      >
                        <span class="">
                          {{ item }}
                        </span>
                        <v-icon small> mdi-close </v-icon>
                      </v-chip>
                    </template>
                  </v-combobox>
                  <v-text-field
                    v-model="youtubeUrl"
                    label="Enter youtube url"
                    required
                    x-large
                    rounded
                    outlined
                    class="mt-6"
                  ></v-text-field>
                  <v-text-field
                    v-model="discordUrl"
                    label="Enter discord url"
                    required
                    x-large
                    rounded
                    outlined
                  ></v-text-field>
                  <v-card-actions>
                    <v-btn
                      :disabled="invalid"
                      :loading="saveLoader"
                      color="primary"
                      block
                      depressed
                      @click="save"
                      >Save</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-btn>
          </validation-observer>
        </div>
      </div>

      <div class="column-2 col w-full px-2 py-3 relative">
        <v-dialog v-model="confirmDialog" width="380px">
          <template #activator="{ props }">
            <div
              v-bind="props"
              style="display: flex; align-items: center; max-width: 20px"
              class="mt-9"
              @click="confirmDialog = true"
            >
              <v-btn icon>
                <v-icon large>mdi-chevron-double-left</v-icon>
              </v-btn>
              <span style="margin-left: 5px; cursor: pointer"><h3>Back</h3></span>
            </div>
          </template>
          <v-card>
            <v-card-title>
              <div class="overline font-weight-bold">
                <h3>Are you sure you want to discard changes?</h3>
              </div>
              <div class="ml-auto">
                <v-btn icon large @click="confirmDialog = false"><v-icon>mdi-close</v-icon></v-btn>
              </div>
            </v-card-title>

            <v-divider></v-divider>

            <v-card-actions class="d-flex justify-center">
              <v-btn
                class="px-4 ma-2"
                x-large
                rounded
                depressed
                outlined
                @click="confirmDialog = false"
              >
                Cancel
              </v-btn>
              <v-btn
                color="red"
                class="px-4 ma-2"
                x-large
                rounded
                depressed
                dark
                :ripple="false"
                @click="goBack"
                ><v-icon left>mdi-trash-can-outline</v-icon>Discard</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
        <div class="form-box pa-0" :style="'borderColor:' + colorValue">
          <div style="position: relative">
            <div
              style="
                position: absolute;
                display: flex;
                justify-content: center;
                align-items: center;
                align-self: center;
                left: 44%;
                top: 30px;
              "
            >
              <div
                style="cursor: pointer"
                class="header_icon rounded-circle"
                @click="openDrawer('icon')"
              >
                <v-icon color="" light x-large>{{ iconType }}</v-icon>
              </div>
            </div>
            <div
              style="
                text-align: center;
                font-size: 31px;
                top: 80px;
                position: relative;
                cursor: pointer;
              "
              @click="openDrawer('title')"
            >
              <h4 style="position: relative">{{ adkTitle }}</h4>
            </div>

            <div class="" style="top: 105px; position: relative">
              <v-divider class="" style="color: grey; border: 1px solid #dedede"></v-divider>
            </div>
          </div>

          <draggable
            class="pa-6"
            empty-insert-threshold="200"
            :list="formsFields"
            group="forms"
            :sort="true"
            :options="{
              handle: '.dragged',
              filter: '.not-draggable',
              animation: 150,
              disabled: false
            }"
            style="
              min-height: 200px;
              position: relative;
              display: flex;
              flex-direction: column;
              margin-top: 120px;
            "
            :move="onMove"
            @change="itemChanged"
          >
            <div
              v-if="formsFields && formsFields.length < 1"
              style="
                text-align: center;
                color: gray;
                font-size: 31px;
                top: 80px;
                position: relative;
              "
            >
              <h4 style="position: relative">No activities yet...</h4>
            </div>
            <div
              v-for="(element, index) in formsFields"
              v-else
              :key="index"
              :class="
                element.hasOwnProperty('component_name') &&
                element.component_name &&
                noEditMolecules.includes(element.component_name)
                  ? 'disable-molecule'
                  : ''
              "
              v-bind="element"
              style="position: relative; display: flex; flex-direction: column; margin-top: 20px"
            >
              <div v-if="element.name === 'multiselect'" class="dragged mt-10">
                <div v-if="activeIndex === index" class="deleteComponent">
                  <v-icon class="deleteImage" style="cursor: pointer" @click="deleteHandler(index)"
                    >mdi-trash-can-outline</v-icon
                  >
                </div>
                <div
                  class="draggable-container mt-5"
                  @click="
                    noEditMolecules.includes(element.component_name)
                      ? ((activeIndex = index), (isDrawerOpen = false))
                      : changeDrawer(index, element)
                  "
                >
                  <div
                    v-if="activeIndex === index"
                    ref="draggableContainerRef"
                    class="draggable-container-preview"
                    :class="{ active: activeIndex === index }"
                  ></div>
                  <div v-for="(field, index) in element.fields" :key="index">
                    <AHeading v-if="field.component_name === 'headText'" :styling="field.styling">
                      <div class="header" v-html="field.title"></div>
                    </AHeading>
                    <component
                      :is="field.component_name"
                      v-else
                      v-model="field.props"
                      v-bind="field"
                      mode="build"
                      :key-name="field.model"
                    ></component>
                  </div>
                </div>
              </div>
              <div
                v-else-if="
                  element.component_name !== 'MSaveAdkOnly' &&
                  element.component_name !== 'm-save-adk-only' &&
                  element.component_name !== 'm-videoask'
                "
                class="dragged"
              >
                <div v-if="activeIndex === index" class="deleteComponent">
                  <v-icon class="deleteImage" style="cursor: pointer" @click="deleteHandler(index)"
                    >mdi-trash-can-outline</v-icon
                  >
                </div>
                <div
                  v-if="element.active !== false && element.name !== 'btn_save'"
                  class="draggable-container mt-5"
                  @click="
                    noEditMolecules.includes(element.component_name)
                      ? ((activeIndex = index), (isDrawerOpen = false))
                      : changeDrawer(index, element)
                  "
                >
                  <div
                    v-if="activeIndex === index"
                    ref="draggableContainerRef"
                    class="draggable-container-preview"
                    :class="{ active: activeIndex === index }"
                  ></div>
                  <div class="draggableMainDiv">
                    <AHeading
                      v-if="element.component_name === 'headText'"
                      :styling="element.styling"
                    >
                      <div class="header" v-html="element.title"></div>
                    </AHeading>
                    <div
                      v-else-if="
                        element.component_name === 'noSetup' ||
                        element.component_name === 'noTweaks'
                      "
                      :styling="element.styling"
                      class="module-setup__none"
                    >
                      {{ element.title }}
                    </div>
                    <div v-else>
                      <component
                        :is="element.component_name"
                        v-model="element.props"
                        v-bind="element"
                        :items="element.checkboxItems"
                        mode="build"
                        :key-name="element.model"
                        :video-ask-url="videoAskUrl(element.videoAskUrl)"
                      ></component>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </draggable>

          <div
            v-if="
              saveAdkOnly.component_name === 'MSaveAdkOnly' ||
              saveAdkOnly.component_name === 'm-save-adk-only'
            "
            class="not-draggable pa-9"
          >
            <div class="mt-5" style="pointer-events: none">
              <div ref="draggableContainerRef"></div>
              <component
                :is="saveAdkOnly.component_name"
                v-model="saveAdkOnly.props"
                v-bind="saveAdkOnly"
                mode="build"
                :key-name="saveAdkOnly.model"
              ></component>
            </div>
          </div>
        </div>
      </div>
      <div class="column-3">
        <Drawer
          :drawer="isDrawerOpen"
          :type="isIcon"
          :title="adkTitle"
          @emitTitle="setTitle($event)"
          @emitType="setType($event)"
          @close="closeDrawer"
        />
      </div>
    </div>
    <div v-if="isLoading" class="vue-form-row">
      <div class="column-1 col px-2 bg-light border rounded">
        <v-stepper vertical class="guide-bar__stepper main_timeline_bar">
          <v-stepper-step
            class="category guide-bar__timeline-category"
            color="black"
            :complete-icon="'mdi-blur'"
            :edit-icon="'$radioOff'"
            :complete="true"
            :editable="false"
            :step="0"
            @click="toggleCategory('pre')"
            ><h2>Make Activity</h2></v-stepper-step
          >
          <draggable
            class="inner"
            :list="fieldsValue"
            :group="{ name: 'forms', pull: 'clone', put: false }"
          >
            <div v-for="(field, index) in fieldsValue" :key="index">
              <div class="draggable_div d-flex">
                <img style="height: 25px; width: 25px" :src="field.draggable_icon || field.icon" />
                <p class="font-weight-bold comp-name">
                  {{ field.draggable_name }}
                </p>
              </div>
            </div>
          </draggable>
        </v-stepper>
        <div class="text-center pa-0 ma-0">
          <v-btn
            :disabled="formsFields.length <= 0 ? true : false"
            color="primary"
            rounded
            class="font-weight-bold"
            depressed
            x-large
            @click="dialog = true"
          >
            Save Activity
          </v-btn>
        </div>
      </div>
      <div class="column-2 col w-full px-2 py-3 relative">
        <v-col class="text-center draggble-progress" cols="12">
          <v-progress-circular
            :size="60"
            :width="10"
            :rotate="360"
            indeterminate
          ></v-progress-circular>
        </v-col>
        <v-col class="text-center" cols="12">
          <span>Loading...</span>
        </v-col>
      </div>
    </div>
  </div>
</template>
<script>
import AComboBox from '@/components/atoms/ACombobox.vue';
import draggable from 'vuedraggable';
import ATextInput from '@/components/atoms/ATextInput.vue';
import ATeleInput from '@/components/atoms/ATeleInput.vue';
import AButton from '@/components/atoms/AButton.vue';
import ADatePicker from '@/components/atoms/ADatePicker.vue';
import ARangeSlider from '@/components/atoms/ARangeSlider.vue';
import ATextArea from '@/components/atoms/ATextArea.vue';
import ACheckBox from '@/components/atoms/ACheckbox.vue';
import ACheckBoxGroup from '@/components/atoms/ACheckBoxGroup.vue';
import MVideoaskRecord from '@/components/molecules/m-videoask-record.vue';
import metagrabber from '@/components/molecules/m-meta-grabber.vue';
import MModule from '@/components/molecules/m-module.vue';
import AVSelect from '@/components/atoms/a-v-select.vue';
import ARadioGroup from '@/components/atoms/ARadioGroup.vue';
import divider from '@/components/atoms/ADivider.vue';
import MListItem from '@/components/molecules/m-list-item.vue';
import MTeam from '@/components/molecules/m-team.vue';
import MPresentation from '@/components/molecules/deliverable/m-presentation.vue';
import MTimelineTree from '@/components/molecules/m-timeline-tree.vue';
import MDiscord from '@/components/molecules/m-discord.vue';
import MTimesheetDefault from '@/components/molecules/m-timesheet-default.vue';
import MMinutesLog from '@/components/molecules/m-minutes-log.vue';
import MProgramFaq from '@/components/molecules/program-faq/m-program-faq.vue';
import AAutocomplete from '@/components/atoms/AAutocomplete.vue';
import AHeading from '@/components/atoms/AHeading.vue';
import MCheckoutForm from '@/components/molecules/m-checkout-form.vue';
import MContactAdk from '@/components/molecules/m-contact-adk.vue';
import mBuildScope from '@/components/molecules/m-build-scope.vue';
import MMeetAdk from '@/components/molecules/m-meet-adk.vue';
import MCalendar from '@/components/molecules/MCalendar.vue';
import MWelcomeAdk from '@/components/molecules/m-welcome-adk.vue';
import MUseToken from '@/components/molecules/m-use-token.vue';
import MVerifyPhoneDialog from '@/components/molecules/m-verify-phone-dialog.vue';
import MVideoaskGeneration from '@/components/molecules/m-videoask-generation.vue';
import MVideoask from '@/components/molecules/m-videoask.vue';
import MVideoLinksViewer from '@/components/molecules/m-video-links-viewer.vue';
import MMatterport from '@/components/molecules/m-matterport.vue';
import store, { useUserState, useFormsActions, useUserActions } from '@/store';
import { ActionTypes } from '@/store/modules/tools/actions';
import {
  EXCLUDE_PROPERTIES,
  PROGRAM_DEFAULT_PROPERTIES,
  PREDEFINED_EDIT_FIELDS,
  noEditMolecules,
  disableDragOnceDropped,
  staticButtons,
  multiSelectMolecule
} from '@/constants/form-builder';
import { computed, ref, onMounted, watch } from '@vue/composition-api';
import MDeliverableAdk from '@/components/molecules/deliverable/m-deliverable-adk.vue';
import _ from 'lodash';
import { COMPONENTS_JSON } from '@/components/molecules/const';
import { ObjectId } from 'bson';
import Drawer from './Drawer.vue';
import MSaveAdkOnly from '../../components/molecules/m-save-adk-only.vue';
import getFormSchema from '../../api/formSchemaApi';

export default {
  name: 'Builder',
  components: {
    MVideoLinksViewer,
    ATextInput,
    draggable,
    ATeleInput,
    AButton,
    ADatePicker,
    ARangeSlider,
    ATextArea,
    ACheckBox,
    ACheckBoxGroup,
    MVideoaskRecord,
    MVideoask,
    metagrabber,
    AVSelect,
    ARadioGroup,
    divider,
    AComboBox,
    MListItem,
    MTeam,
    MPresentation,
    MTimelineTree,
    MDiscord,
    MTimesheetDefault,
    MMinutesLog,
    MProgramFaq,
    AAutocomplete,
    AHeading,
    MCheckoutForm,
    MContactAdk,
    mBuildScope,
    MMeetAdk,
    MCalendar,
    MWelcomeAdk,
    MUseToken,
    MVerifyPhoneDialog,
    MVideoaskGeneration,
    MDeliverableAdk,
    Drawer,
    MModule,
    MSaveAdkOnly,
    MMatterport
  },
  props: {
    edit: {
      required: false,
      type: Boolean,
      default: false
    },
    adkType: {
      required: false,
      type: String,
      default: 'welcome'
    },
    programDoc: {
      required: false,
      type: Array,
      default: () => []
    }
  },
  setup(props, ctx) {
    const confirmDialog = ref(false);
    const { user } = useUserState(['user']);
    const { type, index, category } = ctx.root.$route.query;
    const { id, schemaType } = ctx.root.$route.params;
    let { page } = ctx.root.$route.params;
    const isHover = ref(false);
    const iconType = ref('mdi-telegram');
    const draggableContainerRef = ref('');
    const adkTitle = ref('Untitled');
    const programAdks = ref([]);
    const activeIndex = ref('');
    const pageIndex = ref(Number(index));
    const formsFields = ref([]);
    const offerAdkFields = ref([]);
    const categories = ref([]);
    const selectedCategory = ref('project');
    const dialog = ref(false);
    const adkName = ref('');
    const fields = ref([]);
    const fieldsValue = ref(COMPONENTS_JSON);
    const { postProps: passDrawerElements } = useFormsActions(['postProps']);
    const isIcon = ref('');
    const isDrawerOpen = ref(false);
    const isLoading = ref(true);
    const saveLoader = ref(false);
    const program = ref({});
    const fieldCounts = {};
    const addedItems = ref([]);
    const userType = 'employer';
    const selectedColor = ref('');
    const colorValue = ref('');
    const goal = ref('');
    const boilerInstructions = ref();
    const boilerInstructionsOptions = ref([]);
    const boilerInstructionsSearch = ref();
    const youtubeUrl = ref();
    const discordUrl = ref();
    const saveAdkOnly = ref(staticButtons);
    const colors = ref([
      { color: '#000000', value: 'Black' },
      { color: '#6eba80', value: 'Green' },
      { color: '#3c9dcd', value: 'Blue' },
      { color: '#ea6764', value: 'Red' },
      { color: '#eda1bf', value: 'Pink' },
      { color: '#fec34b', value: 'Yellow' },
      { color: '#bdbdbd', value: 'Gray' },
      { color: '#ae90b0', value: 'Purple' },
      { color: '#f79961', value: 'Orange' }
    ]);

    watch(selectedColor, (curr, old) => {
      colorValue.value = colors?.value?.find(c => c?.value === curr).color;
    });

    const {
      fetchTemplateById,
      updateProgramTemplate,
      fetchProgramById,
      fetchDraft,
      updateProgramData,
      addNewAdk
    } = useUserActions([
      'fetchProgramById',
      'fetchDraft',
      'fetchTemplateById',
      'updateProgramTemplate',
      'updateProgramData',
      'addNewAdk'
    ]);

    const programIdValue = computed(() => {
      return id;
    });

    const goBack = () => {
      if (localStorage?.getItem('redirectionPath')) {
        ctx.root.$router.push(localStorage?.getItem('redirectionPath'));
        localStorage?.removeItem('redirectionPath');
      } else if (schemaType === 'template') {
        ctx.root.$router.push({
          name: 'templateSetup',
          params: {
            templateId: id,
            page
          }
        });
      } else {
        ctx.root.$router.push({
          name: 'guide',
          params: {
            programId: id,
            page
          }
        });
      }
    };

    const videoAskUrl = computed(() => {
      return url =>
        `${url}#contact_name=${encodeURI(user?.value?.firstName)}&contact_email=${encodeURI(
          user?.value?.email
        )}&contact_phone_number=${encodeURI(user?.value?.phoneNumber)}&program_id=${encodeURI(
          programIdValue?.value
        )}&adk_name=${encodeURI(adkName?.value)}`;
    });
    let programData;
    const deleteHandler = index => {
      passDrawerElements({
        isOpen: false
      });
      isDrawerOpen.value = false;
      activeIndex.value = '';
      const comp_name = formsFields?.value?.[index]?.component_name;
      if (comp_name === 'MSaveAdkOnly' && addedItems?.value?.length > 0) {
        const itemIndex = addedItems?.value?.indexOf?.(comp_name);
        if (index !== -1) {
          addedItems?.value?.splice(itemIndex, 1);
        }
      }
      formsFields.value.splice(index, 1);
      if (
        formsFields.value.length === 1 &&
        formsFields.value.find(
          field =>
            field?.component_name === 'm-save-adk-only' || field?.component_name === 'MSaveAdkOnly'
        )
      ) {
        const saveMoleculeAdkIndex = formsFields.value.findIndex(
          field =>
            field?.component_name === 'm-save-adk-only' || field?.component_name === 'MSaveAdkOnly'
        );
        if (saveMoleculeAdkIndex !== -1) {
          formsFields.value.splice(saveMoleculeAdkIndex, 1);
        }
      }
    };

    const changeDrawer = (index, element) => {
      passDrawerElements({
        isOpen: true,
        element
      });
      isIcon.value = '';
      isDrawerOpen.value = true;
      activeIndex.value = index;
    };

    const setTitle = title => {
      adkTitle.value = title;
      adkName.value = title;
    };

    watch(adkName, (curr, old) => {
      adkTitle.value = curr;
    });

    function generateUniqueFormId(name) {
      const baseName = name; // Replace with your desired base name
      const timestamp = Date.now();
      const uniqueId = `${baseName}_${timestamp}`;
      return uniqueId;
    }

    const itemChanged = item => {
      if (item?.added?.element) {
        let { name } = item.added.element;
        const { component_name } = item.added.element;
        if (component_name === 'MSaveAdkOnly') {
          addedItems.value.push(component_name);
        }
        const { newIndex } = item.added;
        if (fieldCounts[name]) {
          fieldCounts[name]++;
          name = `${name}${id}_${fieldCounts[name]}`;
        } else {
          fieldCounts[name] = 1;
        }
        if (formsFields?.value[newIndex]) {
          formsFields.value[newIndex].name = name;
        }
        if (formsFields.value[newIndex]?.model) {
          const uniqueModelName = generateUniqueFormId(name);
          formsFields.value[newIndex].model = uniqueModelName;
        }
        if (component_name !== 'MContactAdk') {
          changeDrawer(item?.added?.index, item?.added?.element);
        }
      }
    };

    const itemDropped = newField => {
      const fields = [];
      const droppedItem = formsFields?.value[newField.newIndex];
      if (droppedItem?.component_name === 'metagrabber') {
        droppedItem.props?.fields?.forEach(element => {
          if (element.name !== 'metagrabber') {
            fields.push({
              component_name: element?.type === 'molecule' ? element?.name : element?.type,
              ...element
            });
          } else {
            droppedItem.props.fields = [element];
            fields.push(droppedItem);
          }
        });
        formsFields.value.splice(newField?.newIndex, 1, {
          name: 'multiselect',
          fields
        });
      }
      if (droppedItem?.component_name === 'offer') {
        if (formsFields?.value?.length > 0) {
          formsFields?.value?.splice(newField.newIndex, 1, ...offerAdkFields.value);
        }
      }
      // Find the index of 'm-save-adk-only' in formsFields
      const saveAdkIndex = formsFields?.value?.findIndex(
        field => field?.component_name === 'm-save-adk-only'
      );
      if (saveAdkIndex !== -1) {
        const saveAdkField = formsFields?.value?.splice(saveAdkIndex, 1)[0];
        formsFields.value.push(saveAdkField);
      }
    };

    const clone = field => {
      const newField = JSON.parse(JSON.stringify(field));
      return newField;
    };

    const fetchStylingProperties = (item, predefined) => {
      const fieldStyle = predefined;
      const styling = {};
      if (item) {
        Object.keys(item).forEach(key => {
          styling[key] = fieldStyle?.[key] || item?.[key];
        });
      }
      return styling;
    };

    const onMove = element => {
      if (element?.relatedContext?.element?.component_name === 'MSaveAdkOnly') {
        return false;
      }
      return true;
    };

    const setOfferFields = program => {
      const offerField = program?.find(adk => adk?.component_name === 'offer');
      const { fields } = offerField?.props;
      const offerFields = fields;
      if (offerFields?.length > 0) {
        const draggableFields = [];
        offerFields?.forEach(elem => {
          const atomAndMolecule = {
            ...elem,
            component_name: elem?.type === 'molecule' ? elem?.name : elem?.type,
            comp_type: elem?.type === 'molecule' ? 'molecule' : 'atom'
          };
          draggableFields.push(atomAndMolecule);
        });
        if (draggableFields?.length > 0) {
          offerAdkFields.value = draggableFields;
        }
      }
    };

    const checkIsActive = name => {
      if (name === 'm-checkoutForm') {
        return false;
      }
      return true;
    };

    onMounted(async () => {
      try {
        if (schemaType === 'template') {
          programData = await fetchTemplateById(id);
        } else if (schemaType === 'ProgramRevision') {
          programData = await fetchDraft({ program_id: id });
        } else {
          programData = await fetchProgramById({ program_id: id });
        }

        const formElements = await getFormSchema();
        program.value = programData;
        programAdks.value = programData?.adks;
        if (category) {
          selectedCategory.value = category;
        }
        const categoriesArray = program?.value?.categories;
        if (categoriesArray?.length > 0) {
          categories.value = categoriesArray?.map(element => element?.name);
        }
        fields.value = formElements;
        if (type === 'edit') {
          let programFields;
          const programAdk = programData?.adks[page];
          adkName.value = programAdk?.name;
          selectedColor.value = colors?.value?.find(
            c => c?.color === programAdk?.styling?.borderColor
          ).value;
          colorValue.value = colors?.value?.find(
            c => c?.color === programAdk?.styling?.borderColor
          ).color;
          goal.value = programAdk?.instruction?.goal;
          boilerInstructions.value = programAdk?.instruction?.boilerInstructions;
          youtubeUrl.value = programAdk?.instruction?.youtubeUrl;
          discordUrl.value = programAdk?.instruction?.discordUrl;
          // show setup screen in form-builder.
          if (programAdk?.icon) {
            iconType.value = programAdk?.icon;
          }
          if (programAdk.setupRequired === false) {
            programFields = programAdk?.presetFields;
          } else {
            programFields = programAdk?.fields;
          }
          // Remove predefined fields and presetFields
          const removeFields = _.omit(programAdk, ['fields', 'presetFields']);
          // extracts all fields and stored in separate array to load inside form-builder
          const formFields = [];
          const builderTypeFields = {};
          const orderArray = []; // Array to maintain the order of the fields

          programFields.forEach((item, index) => {
            // contains default label and other props values.
            const editPredefined = PREDEFINED_EDIT_FIELDS;
            const field = editPredefined?.find(field => field?.name === item?.name)?.styling;
            const predefinedStyle = item?.styling;
            let styling;
            if (field) {
              // fetch labels and other stored props.
              styling = fetchStylingProperties(field, predefinedStyle);
            } else {
              // no style values.
              styling = {};
            }
            const fieldObject = {
              comp_type: item?.type === 'molecule' ? 'molecule' : 'atom',
              component_name: item?.type === 'molecule' ? item?.name : item?.type,
              ...(item.type === 'molecule'
                ? {
                    props: {
                      ...removeFields,
                      fields: Array({
                        ...item,
                        styling
                      }),
                      adkType: programAdk?.name,
                      name: item?.name
                    }
                  }
                : item),
              videoAskUrl: item?.videoAskUrl,
              disabled: true,
              mode: 'build',
              userType,
              active: checkIsActive(item?.name),
              disabledInput: true
            };
            if (item?.builderType && multiSelectMolecule?.includes(item?.builderType)) {
              // Add fields with the same builderType to the builderTypeFields object
              if (builderTypeFields[item.builderType]) {
                builderTypeFields[item.builderType]?.fields?.push(fieldObject);
              } else {
                builderTypeFields[item.builderType] = {
                  name: 'multiselect',
                  fields: Array(fieldObject)
                };
              }
            } else {
              const name = item?.name ? item?.name : item?.type;
              builderTypeFields[name + index] = fieldObject;
            }
          });

          // Push the merged arrays from builderTypeFields to formFields
          Object.values(builderTypeFields).forEach(builderTypeField => {
            formFields.push(builderTypeField);
          });
          // assigning fieldsArray to form-builder to atoms/molecules.
          formsFields.value = formFields;
        } else {
          selectedColor.value = 'Black';
          adkName.value = adkTitle?.value;
          // retrieve/set offer adk fields
          setOfferFields(formElements);
        }
        isLoading.value = false;
      } catch (error) {
        isLoading.value = false;
      }
    });

    const sortAdks = adks => {
      //  reassign position for each adks
      const rePositionedArray = adks?.map((element, index) => {
        return { ...element, position: index };
      });
      //  Sort adks based on positions
      const sortArray = rePositionedArray?.sort((a, b) => a.position - b.position);
      return sortArray;
    };

    const redirectBack = (isTemplate, length) => {
      if (localStorage?.getItem('redirectionPath')) {
        ctx.root.$router.push(localStorage?.getItem('redirectionPath'));
        localStorage?.removeItem('redirectionPath');
      } else if (isTemplate) {
        ctx.root.$router.push({
          name: 'templateSetup',
          params: {
            templateId: id,
            page: length
          }
        });
      } else {
        ctx.root.$router.push({
          name: 'guide',
          params: {
            programId: id,
            page: length
          }
        });
      }
    };

    const setType = iconValue => {
      iconType.value = iconValue;
    };

    const getLastPositionOfCategory = category => {
      if (category) {
        const selectedCategoryIndex = categories?.value?.indexOf(category);
        const aboveCategories = categories?.value?.slice(0, selectedCategoryIndex);
        const positions = programAdks?.value
          .filter(adk => aboveCategories?.includes(adk?.category))
          .map(adk => adk.position);
        const maxPosition = Math.max(...positions);
        return maxPosition;
      }
      return false;
    };

    const dispatchSnackBar = (type, message) => {
      store.dispatch(`tools/${ActionTypes.showSnackbar}`, {
        message,
        type,
        isShowSnackbar: true
      });
    };

    const closeDrawer = () => {
      activeIndex.value = '';
      isDrawerOpen.value = false;
    };

    const checkIfSaveAdkOnlyExists = computed(() => {
      return formsFields.value.findIndex(
        field =>
          field?.component_name === 'm-save-adk-only' || field?.component_name === 'MSaveAdkOnly'
      );
    });

    const checkOnlySaveAdkExists = computed(() => {
      return (
        formsFields.value.length === 1 &&
        formsFields.value.find(
          field => field?.component_name === 'noTweaks' || field?.component_name === 'noSetup'
        )
      );
    });

    const save = async () => {
      if (checkIfSaveAdkOnlyExists?.value === -1) {
        formsFields?.value?.push(saveAdkOnly?.value);
      }
      saveLoader.value = true;
      pageIndex.value = Number(index);
      let allFields = [];
      const staticAdkSettings = { ...PROGRAM_DEFAULT_PROPERTIES };
      staticAdkSettings.category = selectedCategory?.value;
      staticAdkSettings.icon = iconType?.value;
      const isTemplate = schemaType === 'template';
      const adk_name = adkName?.value;
      let presetFieldList = [];
      let position = 0;
      let isCategoryMatch = true;
      if (selectedCategory?.value !== category) {
        isCategoryMatch = false;
      }
      let saveAdkData = true;
      formsFields?.value?.forEach(element => {
        if (element?.component_name === 'ADatePicker') {
          if (element?.minDate === '') {
            dispatchSnackBar('error', 'You must be need to select minDate.');
            dialog.value = false;
            saveLoader.value = false;
            saveAdkData = false;
          } else if (element?.minDate > element?.maxDate) {
            dispatchSnackBar('error', 'minDate must be less than maxDate.');
            dialog.value = false;
            saveLoader.value = false;
            saveAdkData = false;
          }
        }
        let item;
        if (element?.name === 'multiselect') {
          const { fields } = element;
          fields.forEach(item => {
            const { props, component_name } = item;

            if (props?.styling) staticAdkSettings.styling = props?.styling;
            if (props?.isSkippable) staticAdkSettings.isSkippable = props?.isSkippable;
            if (props?.id) staticAdkSettings.id = new ObjectId(props?.id?.toString());
            if (props?.credentials) {
              staticAdkSettings.credentials = props?.credentials;
            }
            if (props?.rewards) {
              staticAdkSettings.rewards = props?.rewards;
            }
            if (props?.isShowFeedBackForm) {
              staticAdkSettings.isShowFeedBackForm = props?.isShowFeedBackForm;
            }
            if (props?.discordEntityType) {
              staticAdkSettings.discordEntityType = props?.discordEntityType;
            }
            if (props?.timeInForCountDown) {
              staticAdkSettings.timeInForCountDown = props?.timeInForCountDown;
            }
            if (props?.idealActivityCompletionTime) {
              staticAdkSettings.idealActivityCompletionTime = props?.idealActivityCompletionTime;
            }
            if (props?.activitySettings) {
              staticAdkSettings.activitySettings = props?.activitySettings;
            }
            if (props?.activityStartedOn) {
              staticAdkSettings.activityStartedOn = props?.activityStartedOn;
            }
            if (props?.timeTaken) {
              staticAdkSettings.timeTaken = props?.timeTaken;
            }
            if (props?.ratings) {
              staticAdkSettings.ratings = props?.ratings;
            }
            if (props?.ramarks) {
              staticAdkSettings.ramarks = props?.ramarks;
            }

            if (component_name === 'metagrabber') {
              staticAdkSettings.isPreview = true;
            } else {
              staticAdkSettings.isPreview = false;
            }

            if (props) {
              //  fetching fields and presetFields from props.
              const { fields, presetFields } = props;
              if (fields) {
                fields.forEach(elem => {
                  const field = elem;
                  field.position = position;
                  allFields.push(field);
                  position += 1;
                });
                if (presetFields?.length > 0) presetFieldList = presetFields;
              } else {
                const excludeValues = _.omit(item, ['props']);
                const field = excludeValues;
                field.position = position;
                allFields.push(field);
                if (presetFields?.length > 0) presetFieldList = presetFields;
                position += 1;
              }
            } else {
              const excludeValues = _.omit(item, EXCLUDE_PROPERTIES);
              const excludeStyling = _.omit(item?.styling, EXCLUDE_PROPERTIES);
              excludeValues.styling = excludeStyling;
              excludeValues.position = position;
              allFields?.push(excludeValues);
              position += 1;
            }
          });
        } else {
          item = element;
          const { props, component_name } = item;
          if (props?.styling) staticAdkSettings.styling = props?.styling;
          if (props?.isSkippable) staticAdkSettings.isSkippable = props?.isSkippable;
          if (props?.id) staticAdkSettings.id = new ObjectId(props?.id?.toString());
          if (props?.credentials) {
            staticAdkSettings.credentials = props?.credentials;
          }
          if (props?.rewards) {
            staticAdkSettings.rewards = props?.rewards;
          }
          if (props?.isShowFeedBackForm) {
            staticAdkSettings.isShowFeedBackForm = props?.isShowFeedBackForm;
          }
          if (props?.discordEntityType) {
            staticAdkSettings.discordEntityType = props?.discordEntityType;
          }
          if (props?.timeInForCountDown) {
            staticAdkSettings.timeInForCountDown = props?.timeInForCountDown;
          }
          if (props?.idealActivityCompletionTime) {
            staticAdkSettings.idealActivityCompletionTime = props?.idealActivityCompletionTime;
          }
          if (props?.activitySettings) {
            staticAdkSettings.activitySettings = props?.activitySettings;
          }
          if (props?.activityStartedOn) {
            staticAdkSettings.activityStartedOn = props?.activityStartedOn;
          }
          if (props?.timeTaken) {
            staticAdkSettings.timeTaken = props?.timeTaken;
          }
          if (props?.ratings) {
            staticAdkSettings.ratings = props?.ratings;
          }
          if (props?.ramarks) {
            staticAdkSettings.ramarks = props?.ramarks;
          }

          if (component_name === 'metagrabber') {
            staticAdkSettings.isPreview = true;
          } else {
            staticAdkSettings.isPreview = false;
          }
          if (props) {
            //  fetching fields and presetFields from props.
            const { fields, presetFields } = props;
            if (fields) {
              fields.forEach(elem => {
                const field = elem;
                field.position = position;
                allFields?.push(field);
                position += 1;
              });
              if (presetFields?.length > 0) presetFieldList = presetFields;
            } else {
              const excludeValues = _.omit(item, ['props']);
              const field = excludeValues;
              field.position = position;
              allFields?.push(field);
              if (presetFields?.length > 0) presetFieldList = presetFields;
              position += 1;
            }
          } else {
            const excludeValues = _.omit(item, EXCLUDE_PROPERTIES);
            const excludeStyling = _.omit(item?.styling, EXCLUDE_PROPERTIES);
            excludeValues.styling = excludeStyling;
            excludeValues.position = position;
            allFields?.push(excludeValues);
            position += 1;
          }
        }
      });

      if (saveAdkData) {
        let response;
        let adks = programAdks.value;
        staticAdkSettings.styling.borderColor = colors?.value?.find(
          c => c?.value === selectedColor?.value
        ).color;
        if (!staticAdkSettings?.instruction) {
          staticAdkSettings.instruction = {};
        }
        if (goal?.value) staticAdkSettings.instruction.goal = goal?.value;
        if (boilerInstructions?.value)
          staticAdkSettings.instruction.boilerInstructions = boilerInstructions?.value;
        if (youtubeUrl?.value) staticAdkSettings.instruction.youtubeUrl = youtubeUrl?.value;
        if (discordUrl?.value) staticAdkSettings.instruction.discordUrl = discordUrl?.value;
        // assign document_type
        let document_type;
        if (schemaType === 'template') {
          document_type = 'ProgramSchema';
        } else if (schemaType === 'program') {
          document_type = 'Program';
        } else {
          document_type = 'ProgramRevision';
        }
        if (type === 'edit') {
          let res;
          const setupRequired = adks[page]?.setupRequired;
          // if only noTweaks or noSetup molecule then remove MSaveAdkOnly molecule
          if (
            allFields?.length === 2 &&
            allFields?.find(field => field?.type === 'noTweaks' || field?.type === 'noSetup') &&
            allFields?.find(
              field => field?.name === 'm-save-adk-only' || field?.name === 'MSaveAdkOnly'
            )
          ) {
            allFields = allFields?.filter(
              field => field?.name !== 'm-save-adk-only' && field?.name !== 'MSaveAdkOnly'
            );
          }
          // if more then 2 fields and fields have any atoms other then notweaks and MSaveAdkOnly then remove noTweaks
          if (
            allFields?.length > 2 &&
            allFields?.find(field => field?.type === 'noTweaks' || field?.type === 'noSetup') &&
            allFields?.find(
              field => field?.name === 'm-save-adk-only' || field?.name === 'MSaveAdkOnly'
            )
          ) {
            allFields = allFields?.filter(
              field => field?.type !== 'noTweaks' && field?.type !== 'noSetup'
            );
          }
          if (setupRequired) {
            staticAdkSettings.fields = isTemplate
              ? allFields
              : allFields?.map(field => {
                  if (field?.id) {
                    return { ...field, id: new ObjectId(field?.id) };
                  }
                  return { ...field, id: new ObjectId() };
                });
            staticAdkSettings.presetFields = isTemplate
              ? adks[page]?.presetFields
              : adks[page]?.presetFields?.map(field => {
                  if (field?.id) {
                    return { ...field, id: new ObjectId(field?.id) };
                  }
                  return { ...field, id: new ObjectId() };
                });
          } else {
            staticAdkSettings.presetFields = isTemplate
              ? allFields
              : allFields?.map(field => {
                  if (field?.id) {
                    return { ...field, id: new ObjectId(field?.id) };
                  }
                  return { ...field, id: new ObjectId() };
                });
            staticAdkSettings.fields = isTemplate
              ? adks[page]?.fields
              : adks[page]?.fields?.map(field => {
                  if (field?.id) {
                    return { ...field, id: new ObjectId(field?.id) };
                  }
                  return { ...field, id: new ObjectId() };
                });
          }
          staticAdkSettings.setupRequired = adks[page]?.setupRequired;
          staticAdkSettings.name = adk_name;
          if (pageIndex.value >= 0) {
            res = await addNewAdk({
              programOrTemplateId: id,
              adk_name,
              data: staticAdkSettings,
              document_type,
              isCatMatch: isCategoryMatch,
              ...(pageIndex?.value >= 0 && {
                index: pageIndex?.value
              })
            });
            page = pageIndex.value;
          } else {
            adks.splice(page, 1, staticAdkSettings);
            if (isTemplate) {
              adks = sortAdks(adks);
            } else {
              program.value.adks = sortAdks(adks);
            }
            if (isTemplate && !pageIndex.value) {
              res = await updateProgramTemplate({
                payload: {
                  adks
                },
                program_schema_id: id
              });
            } else if (!pageIndex.value) {
              if (program.value.adks[page]?.setupRequired === true) {
                program.value.adks[page].fields = isTemplate
                  ? allFields
                  : allFields?.map(field => {
                      if (field?.id) {
                        return { ...field, id: new ObjectId(field?.id) };
                      }
                      return { ...field, id: new ObjectId() };
                    });
                program.value.adks[page].name = adkName?.value;
              } else {
                program.value.adks[page].presetFields = isTemplate
                  ? allFields
                  : allFields?.map(field => {
                      if (field?.id) {
                        return { ...field, id: new ObjectId(field?.id) };
                      }
                      return { ...field, id: new ObjectId() };
                    });
                program.value.adks[page].name = adkName?.value;
              }
              res = await updateProgramData({
                program_id: id,
                data: program?.value
              });
            }
          }
          if (res && !res?.data?.error) {
            dispatchSnackBar('success', 'ADK Updated Successfully');
            saveLoader.value = false;
            formsFields.value = [];
            redirectBack(isTemplate, page);
          } else {
            saveLoader.value = false;
            dispatchSnackBar('error', res?.data?.error?.description);
          }
        } else {
          staticAdkSettings.fields = isTemplate
            ? allFields
            : allFields?.map(field => {
                if (field?.id) {
                  return { ...field, id: new ObjectId(field?.id) };
                }
                return { ...field, id: new ObjectId() };
              });
          if (presetFieldList?.length > 0) {
            staticAdkSettings.presetFields = isTemplate
              ? presetFieldList
              : presetFieldList?.map(field => {
                  if (field?.id) {
                    return { ...field, id: new ObjectId(field?.id) };
                  }
                  return { ...field, id: new ObjectId() };
                });
          }
          staticAdkSettings.name = adk_name;
          const adkLength = adks.length > 0 ? adks.length : 0;
          const lastPosition = getLastPositionOfCategory(selectedCategory?.value);
          if (lastPosition) {
            staticAdkSettings.position = lastPosition;
          } else {
            staticAdkSettings.position = adkLength;
          }
          staticAdkSettings.id = new ObjectId();
          response = await addNewAdk({
            programOrTemplateId: id,
            adk_name,
            data: staticAdkSettings,
            document_type,
            isCatMatch: isCategoryMatch,
            ...(pageIndex?.value >= 0 && {
              index: pageIndex?.value
            })
          });
          if (response?.data?.ok) {
            formsFields.value = [];
            saveLoader.value = false;
            dialog.value = false;
            dispatchSnackBar('success', 'ADK Added Successfully');
            redirectBack(isTemplate, adkLength);
          } else if (response?.data?.error) {
            dispatchSnackBar('error', response?.data?.error?.description);
            saveLoader.value = false;
          } else {
            dispatchSnackBar('error', 'something went wrong');
            saveLoader.value = false;
          }
        }
      }
    };

    const openDrawer = val => {
      passDrawerElements({
        isOpen: true
      });
      isIcon.value = val;
      activeIndex.value = '';
    };

    return {
      onMove,
      selectedCategory,
      iconType,
      categories,
      colors,
      selectedColor,
      colorValue,
      goal,
      boilerInstructions,
      boilerInstructionsOptions,
      boilerInstructionsSearch,
      youtubeUrl,
      discordUrl,
      itemDropped,
      redirectBack,
      noEditMolecules,
      itemChanged,
      saveLoader,
      isLoading,
      fields,
      fieldsValue,
      formsFields,
      adkName,
      dialog,
      save,
      clone,
      deleteHandler,
      changeDrawer,
      isDrawerOpen,
      videoAskUrl,
      draggableContainerRef,
      isHover,
      activeIndex,
      setType,
      isIcon,
      adkTitle,
      setTitle,
      closeDrawer,
      disableDrag: disableDragOnceDropped,
      addedItems,
      goBack,
      saveAdkOnly,
      type,
      confirmDialog,
      openDrawer,
      checkOnlySaveAdkExists
    };
  }
};
</script>

<style lang="scss">
$step-width: 27px;
$category-width: 45px;

.draggable-container-preview {
  &.active {
    background-color: rgba(23, 196, 149, 0.1);
    border: 1px dashed #07bf9b;
    border-radius: 10px;
    position: absolute;
    top: -10px;
    left: -10px;
    // right: 15px;
    // bottom: 15px;
    height: 104%;
    width: 103%;
    z-index: 9999;
  }
}

.header_icon {
  border: 1px solid grey;
  position: absolute;
  margin: 0px;
  padding: 0px;
  left: 47%;
  background: white;
  padding: 16px;
  cursor: pointer;
  top: -69px;
  align-items: center;
  align-self: center;
  align-content: center;
  margin: 0 auto;
  display: flex;
}
.box {
  position: absolute;
  height: 100%;
  width: -webkit-fill-available;
}

.disable-molecule::before {
  content: '';
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 5px;
  transform: rotate(45deg);
}

.colorSelect {
  position: relative;
  top: 15px;
}

.overlay {
  border: 2px solid greenyellow;
}

.hoverEff:hover {
  border: 5px solid greenyellow;
  opacity: 30%;
}

.editImage {
  height: 30px;
  width: 30px;
}

.deleteComponent {
  position: relative;
  cursor: pointer;
  .deleteImage {
    position: absolute;
    bottom: 1px;
    left: 4px;
    height: 24px;
    width: 18px;
    color: #07bf9b;
  }
}

h3 {
  text-align: center;
}

.main_container {
  margin: 10px;
}

// .draggable_div {
//   gap: 10px;
//   margin: 20px;
// }

.element_container {
  margin: 15px;
}

.container {
  position: relative;
}

.preview_show {
  display: block;
}

.draggable-container {
  position: relative;
  cursor: pointer;
}

.btn-delete {
  position: absolute;
  top: 0;
  right: 0;
}

.draggable_div {
  cursor: pointer;
}

.form-image {
  height: auto;
  width: auto;
  max-height: 550px;
  max-width: 500px;
}

.drag-items {
  height: 100%;
}
.v-dialog {
  .dialog-close {
    height: 20px;
    width: 20px;
    position: absolute;
    right: 0px;
    top: -5px;
  }
  min-width: 600px;
  .v-card {
    padding: 10px;
    .v-input {
      margin: 0;
      padding: 0;
    }
    &__actions {
      padding: 0;
      max-width: 100%;
      .v-btn.primary {
        min-height: 60px;
        min-width: 190px !important;
        padding: 0 19.5555555556px;
        border-radius: 40px;
        max-width: 190px;
        width: 100%;
        height: auto;
        margin: 0 auto;
      }
    }
  }
}
.v-stepper.guide-bar__stepper {
  overflow-y: auto;
  box-shadow: none;
  padding-left: 10px;
  padding-right: auto;
  background-color: transparent !important;

  .v-stepper-step-container:first-child {
    .v-stepper__step {
      padding-top: 0px;
    }
  }

  .v-stepper__step {
    padding: 24px 0 16px 0;
    background-color: transparent !important;

    :hover {
      cursor: pointer;
    }
  }

  :not(.category) {
    ::v-deep .v-stepper__step__step {
      height: $step-width;
      min-width: $step-width;
      width: $step-width;
      margin-left: -1px;
      margin-right: 20px;
    }
    .v-stepper__step:hover {
      ::v-deep .v-stepper__step__step {
        transform: scale(1.1);
      }
      ::v-deep .v-stepper__label {
        transform: scale(1.1);
      }
    }
  }

  .category {
    ::v-deep .v-stepper__step__step {
      height: $category-width;
      min-width: $category-width;
      width: $category-width;
      margin-left: -10px;

      i {
        font-size: 1.25rem;
      }
    }
  }

  .category-stepper-content {
    ::v-deep .v-stepper__wrapper {
      height: 14px !important;
    }
  }
}
.vuedraggable-main {
  .vue-form-row {
    position: relative;
    display: flex;
    height: 100%;
    min-height: calc(100vh - 75px);
    width: 100%;
    overflow-y: hidden;
    .column-2,
    .column-3 {
      height: 100vh;
      overflow-x: hidden;
      overflow-y: auto;
      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #f5f5f5;
      }

      &::-webkit-scrollbar {
        width: 5px;
        background-color: #f5f5f5;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #000000;
        border: 2px solid #555555;
      }
    }
    .column-1 {
      height: 100vh;
      position: relative;
      max-width: 17vw;
      padding: 20px !important;
      h3 {
        padding: 0;
        text-align: left;
        font-weight: bold;
      }
      .inner {
        height: 100%;
      }
      .draggable_div {
        align-items: center;
        padding: 10px;
        border: 1px solid transparent;
        &:hover {
          // box-shadow: 0 1px 8px 0 rgb(0 0 0/8%);
          border: 1px dashed #404142;
          border-radius: 10px;
        }
        img {
          object-fit: contain;
          margin-right: 10px;
        }
        p {
          margin: 0;
          font-size: 14px;
        }
      }
    }
    .column-2 {
      position: relative;
      // border: 12px solid orange;
      z-index: 2;
      .header {
        color: #aaa; /* grayed-out color */
        opacity: 0.7; /* lower opacity */
      }
      // background: rgb(241 245 249);
      .v-btn.primary {
        min-height: 60px;
        min-width: 78px;
        padding: 0 19.5555555556px;
        border-radius: 40px;
        max-width: 190px;
        width: 100%;
        height: auto;
        background: transparent !important;
        border: 1px solid #000;
        box-shadow: none;
        color: #000;
      }
    }
    .column-3 {
      position: absolute;
      right: 0;
      aside {
        // border: 3px solid;
        // border-radius: 5px;
        border-left: 3px solid #dedede;
        position: relative;
        z-index: 9;
        background: #fff;
      }
    }
    .form-box {
      margin: 50px auto;
      position: relative;
      min-height: 500px;
      padding: 20px;
      border-radius: 25px;
      min-width: 308px;
      width: 100%;
      max-width: 700px;
      border: 10px solid #000;
      .v-input__prepend-outer {
        // margin-top: 10px;
        display: none;
      }
      // .v-input__slot {
      //   min-height: 45px;
      // }
      // .v-label {
      //   top: 12px;
      // }
    }
  }
  .draggble-progress {
    position: absolute;
    top: 50%;
  }
  .disable-dragged {
    color: #aaa;
    opacity: 0.5;
    pointer-events: none;
  }
  .main_timeline_bar {
    overflow-y: auto;
    // max-width: 255px;
    max-width: 300px;
    max-height: 88vh;
    // &::-webkit-scrollbar-track {
    //   -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    //   background-color: #f5f5f5;
    // }

    // &::-webkit-scrollbar {
    //   width: 5px;
    //   background-color: #f5f5f5;
    // }

    // &::-webkit-scrollbar-thumb {
    //   background-color: #000000;
    //   border: 2px solid #555555;
    // }
  }
}

.rounded-circle {
  border: 2px solid #dedede;
}

.guide-bar__timeline-category {
  width: fit-content;
}
</style>
