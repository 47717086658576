




























































import { useUserState, useUserActions, useUserGetters } from '@/store';
import { ref, reactive, toRefs, defineComponent, computed, watch } from '@vue/composition-api';
import axios from 'axios';

const API_ENDPOINT = 'https://discord.com/api/users/@me';
const DISCORD_AUTH = `https://discord.com/api/oauth2/authorize?response_type=code&client_id=${process.env.VUE_APP_DISCORD_CLIENT_ID}&redirect_uri=${process.env.VUE_APP_DISCORD_REDIRECT}&response_type=code&scope=identify%20guilds.join`;

export default defineComponent({
  name: 'ModuleDefault',
  components: {
    MDiscordProfile: () => import('@/components/molecules/m-discord-profile.vue'),
    MDiscordChannelRedirection: () =>
      import('@/components/molecules/m-discord-channel-redirection.vue'),
    MDiscordChannel: () => import('@/components/molecules/m-discord-channel.vue'),
    MDiscordConnection: () => import('@/components/molecules/m-discord-connection.vue')
  },
  props: {
    value: {
      required: true,
      type: Object
    },
    userType: {
      required: false,
      type: String,
      default: ''
    },
    adkIndex: {
      type: Number,
      default: 0
    },
    adkType: {
      type: String,
      default: ''
    },
    mode: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup(props, ctx) {
    const { user }: any = useUserState(['user']);
    const { updateAdkData } = useUserActions(['updateAdkData']);
    const { createDiscordChannels } = useUserActions(['createDiscordChannels']);
    const { getProgramDoc } = useUserGetters(['getProgramDoc']);
    const { addMembersToDiscordRole } = useUserActions(['addMembersToDiscordRole']);
    const { refreshDiscordAccessToken } = useUserActions(['refreshDiscordAccessToken']);
    const showSkipConfirmation = ref(false);
    const isDisconnected = ref(false);
    const state = reactive({
      avatarSource: '',
      discordUsername: 'Username #0000',
      accessToken: '',
      studentAdkIndex: -1
    });
    const isFetching = ref(false);
    const isJoining = ref(false);

    const studentDocument = computed({
      get: () => props.value,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });

    const adkIndexValue: any = computed({
      get: () => props.adkIndex,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });

    const programId = computed(() => {
      if (props.userType === 'organizer') {
        return studentDocument.value?.data?._id.toString();
      }
      return studentDocument.value?.data?.program_id.toString();
    });

    const userId = computed(() => {
      if (props.userType === 'organizer') {
        return user.value?._id.toString();
      }
      return studentDocument.value?.data?.participant_id.toString();
    });

    const studentId = computed(() => {
      if (props.userType !== 'organizer') {
        return studentDocument.value?.data?._id.toString();
      }
      return '';
    });
    const adkObject = computed(() => {
      if (props.mode === 'build') {
        return studentDocument.value;
      }
      return studentDocument.value?.data?.adks[adkIndexValue.value];
    });

    const mDiscordField = computed(() => {
      return studentDocument.value?.data?.adks[adkIndexValue.value]?.fields.find(
        field => field.name === 'm-discord'
      )
        ? studentDocument.value?.data?.adks[adkIndexValue.value]?.fields.find(
            field => field.name === 'm-discord'
          )
        : studentDocument.value?.data?.adks[adkIndexValue.value]?.presetFields.find(
            field => field.name === 'm-discord'
          );
    });

    const discordEntityType = computed(() => {
      return adkObject?.value?.discordEntityType || adkObject?.value?.name;
    });

    const fieldStyling = computed(() => {
      const field = adkObject.value.fields.find(item => item.name === 'm-discord');
      const preset = adkObject.value.presetFields?.find(item => item.name === 'm-discord');
      if (field) {
        return field.styling;
      }
      return preset.styling;
    });

    const createAccountLabel = computed(() => {
      return fieldStyling.value?.createAccountLabel;
    });

    const existingAccountLabel = computed(() => {
      return fieldStyling.value?.existingAccountLabel;
    });

    const title = computed(() => {
      return fieldStyling.value?.title;
    });

    const discordInfo: any = computed(() => {
      if (props.userType === 'organizer') {
        return user.value.workspaceDiscordInfo;
      }
      return mDiscordField.value?.value?.discordInfo;
    });

    const isToken = computed(() => {
      if (discordInfo.value && discordInfo.value.discordAccessToken) {
        return true;
      }
      return false;
    });

    function saveProgramId() {
      localStorage.setItem('programId', programId.value);
      localStorage.setItem('adkIndexValue', adkIndexValue.value);
      localStorage.setItem('userType', props.userType);
      localStorage.setItem('userId', userId.value);
      localStorage.setItem('studentId', studentId.value);
      localStorage.setItem('redirectUrl', window.location.href);
    }
    const discordData = ref();
    const getUser = async () => {
      isFetching.value = true;
      try {
        const refreshToken = await refreshDiscordAccessToken({
          userType: props.userType,
          programId: programId.value,
          public_id: studentId.value,
          refreshToken: discordInfo.value.discordRefreshToken
        });
        if (!refreshToken.error) {
          discordInfo.value.avatarSrc = refreshToken.data.avatarSrc;
          discordInfo.value.discordAccessToken = refreshToken.data.discordAccessToken;
          discordInfo.value.discordId = refreshToken.data.discordId;
          discordInfo.value.discordRefreshToken = refreshToken.data.discordRefreshToken;
          discordInfo.value.discordUsername = refreshToken.data.discordUsername;
          discordInfo.value.expiresIn = refreshToken.data.expiresIn;
          discordInfo.value.scope = refreshToken.data.scope;
          const res = await axios.get(API_ENDPOINT, {
            headers: {
              Authorization: `Bearer ${refreshToken.data.discordAccessToken}`
            }
          });
          state.avatarSource =
            res.data.avatar !== null
              ? `https://cdn.discordapp.com/avatars/${res.data.id}/${res.data.avatar}.png`
              : '';
          state.discordUsername = `${res.data.username} #${res.data.discriminator}`;
          discordData.value = mDiscordField?.value.setup?.discordData;
          if (discordEntityType.value === 'workspace' && props.userType === 'participant') {
            if (!discordData.value) {
              const result = await createDiscordChannels({
                public_id: studentId.value,
                program_id: programId.value.toString(),
                program_name: studentDocument.value.data.programName,
                access_token: refreshToken.data.discordAccessToken,
                discord_id: res.data.id,
                user_type: props.userType
              });
              if (result) {
                discordData.value = result.data.data;
              }
            }
          }
          await addMembersToDiscordRole({
            public_id: studentId.value,
            program_id: programId.value.toString(),
            discord_access_token: refreshToken.data.discordAccessToken,
            discord_user_name: res.data.username,
            discord_id: res.data.id
          });
          isFetching.value = false;
        } else {
          isFetching.value = false;
          isDisconnected.value = true;
        }
      } catch (err: any) {
        if (err.response.status === 401) {
          const refreshToken = await refreshDiscordAccessToken({
            userType: props.userType,
            programId: programId.value,
            public_id: studentId.value,
            refreshToken: discordInfo.value.discordRefreshToken
          });
          if (!refreshToken.error) {
            discordInfo.value.avatarSrc = refreshToken.data.avatarSrc;
            discordInfo.value.discordAccessToken = refreshToken.data.discordAccessToken;
            discordInfo.value.discordId = refreshToken.data.discordId;
            discordInfo.value.discordRefreshToken = refreshToken.data.discordRefreshToken;
            discordInfo.value.discordUsername = refreshToken.data.discordUsername;
            discordInfo.value.expiresIn = refreshToken.data.expiresIn;
            discordInfo.value.scope = refreshToken.data.scope;
            state.avatarSource =
              refreshToken.data.avatarSrc !== null
                ? `https://cdn.discordapp.com/avatars/${refreshToken.data.discordId}/${refreshToken.data.avatarSrc}.png`
                : '';
            state.discordUsername = refreshToken.data.discordUsername;
            isFetching.value = false;
          } else {
            isFetching.value = false;
            isDisconnected.value = true;
          }
        } else {
          isFetching.value = false;
          isDisconnected.value = true;
        }
      }
    };

    if (isToken.value) {
      getUser();
    }
    const isSkipDiscard = ref(false);
    async function skipDiscord() {
      isSkipDiscard.value = true;
      let documentType = 'Student';
      if (props.userType === 'organizer') {
        documentType = 'Program';
      }
      studentDocument.value.data.adks[adkIndexValue.value].discordIntegraionSkipped = true;
      studentDocument.value.data.adks[adkIndexValue.value].completed = new Date();
      await updateAdkData({
        program_id: studentDocument.value.data._id.toString(),
        document_type: documentType,
        data: studentDocument.value.data.adks[props.adkIndex]
      });
      studentDocument.value.data = {
        adks: [],
        ...getProgramDoc.value
      };
      setTimeout(() => {
        isSkipDiscard.value = false;
      }, 1000);
      showSkipConfirmation.value = false;
    }

    return {
      discordEntityType,
      title,
      createAccountLabel,
      existingAccountLabel,
      ...toRefs(state),
      skipDiscord,
      showSkipConfirmation,
      isFetching,
      adkObject,
      isJoining,
      saveProgramId,
      adkIndexValue,
      studentDocument,
      programId,
      isToken,
      isDisconnected,
      isSkipDiscard,
      discordData,
      discordInfo,
      DISCORD_AUTH
    };
  }
});
