































import { computed } from '@vue/composition-api';
import Autocomplete from '@trevoreyre/autocomplete-vue';
import '@trevoreyre/autocomplete-vue/dist/style.css';

export default {
  components: {
    Autocomplete
  },
  props: {
    value: {
      type: [String, Array],
      default: ''
    },
    readonly: {
      type: Boolean,
      default: false
    },
    rightIcon: {
      type: String,
      default: null
    },
    leftIcon: {
      type: String,
      default: null
    },
    type: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      default: 'Search...'
    },
    errorMessage: {
      type: [String, Array],
      default: () => {}
    },
    isRounded: {
      type: Boolean,
      default: false
    },
    isRenderer: {
      type: Boolean,
      default: false
    }
  },
  setup(props, ctx) {
    const inputValue = computed({
      get: () => props.value,
      set: newVal => {
        ctx.emit('val', newVal);
        if (props.isRenderer) ctx.emit('input', newVal);
      }
    });

    const apiUrl = process.env.VUE_APP_GOOGLE_PLACES;

    function search(input) {
      const url = `${apiUrl}?query=${encodeURI(input)}&type=${props.type}`;
      /* eslint-disable consistent-return */
      return new Promise(resolve => {
        if (input.length < 3) {
          return resolve([]);
        }
        fetch(url)
          .then(response => {
            if (response.status === 200) {
              return response.json();
            }
            throw new Error('Something went wrong');
          })
          .then(data => {
            if (data.data) {
              resolve(data.data.results);
            }
          })
          .catch(err => {
            resolve([{ none: true }]);
          });
      });
    }

    function myResult(val) {
      if (props.type === 'school') {
        return `${val.name}, ${val.formatted_address}`;
      }
      if (props.type === 'region' || props.type === 'street') {
        return val.formatted_address;
      }
      return val.name;
    }

    function getResultValue(result) {
      return result;
    }

    return {
      apiUrl,
      search,
      getResultValue,
      inputValue,
      myResult
    };
  }
};
