


































































































/* eslint-disable no-prototype-builtins */
import { defineComponent, reactive, toRefs, ref, computed } from '@vue/composition-api';
import {
  questionIsBookmarked,
  questionIsDisliked,
  questionIsFlagged,
  questionIsLiked,
  commentIsLiked,
  commentIsFlagged,
  removeId
} from '@/composables/useForum';
import { useQuestionActions, useQuestionGetters, useUserState } from '@/store';
import useTeamDoc from '@/composables/useTeamDoc';
import { Question as QuestionType } from '@/composables/types';
import Question from './m-question.vue';

const filterOptions = [
  {
    icon: 'account-supervisor-circle-outline',
    label: 'All'
  },
  {
    icon: 'comment-question',
    label: 'My Questions'
  },
  {
    icon: 'bookmark',
    label: 'Bookmarks'
  }
];
const MAX_QUESTIONS_PER_PAGE = 10;
export default defineComponent({
  name: 'MProgramFaq',
  components: {
    Question
  },
  props: {
    value: {
      required: true,
      type: Object
    },
    adkType: {
      type: String,
      default: ''
    },
    userType: {
      type: String,
      default: ''
    },
    updateAdk: {
      type: Function,
      default: () => {}
    },
    isMonitor: {
      type: Boolean,
      default: false
    },
    studentId: {
      type: String,
      default: ''
    }
  },
  setup(props, ctx) {
    const { fetchQuestions, postQuestion, updateQuestion } = useQuestionActions([
      'fetchQuestions',
      'postQuestion',
      'updateQuestion'
    ]);
    const { getQuestions } = useQuestionGetters(['getQuestions']);
    const { user }: any = useUserState(['user']);

    const isFetching = ref(false);
    const teamDoc = ref();
    const { getTeamDoc }: any = useTeamDoc();
    const loading = ref(false);
    const isCommenting = ref(false);

    const programDoc = computed({
      get: () => props.value,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });

    async function loadInitialData() {
      isFetching.value = true;
      teamDoc.value = await getTeamDoc({
        program_id: ctx.root.$route.params.programId || programDoc.value?.data?.program_id,
        user_id: props.isMonitor ? props.studentId : user.value?._id.toString(),
        public_id: props?.value?.data?._id
      });
    }
    loadInitialData();

    const adkData = computed(() => {
      return programDoc.value?.data?.adks?.find(a => a.name === props.adkType);
    });
    const fieldIndex = computed(() => {
      return adkData.value.fields.findIndex(f => f.name === 'm-program-faq');
    });
    const fieldValue = computed({
      get: () => adkData.value.fields[fieldIndex.value].value,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });

    const scrollUp = () => {
      window.scrollTo(0, 300);
    };

    const state = reactive({
      page: 1,
      filter: 'All',
      questionInput: '',
      questions: getQuestions.value,
      programDoc: null,
      studentAdkData: null as null | Record<string, any>,
      teamAdkData: null as null | Record<string, any>,
      teamAdkIndex: -1,
      questionsRemaining: 0,
      showInstructions: true,
      setupInstructions: {
        description: '',
        instructions: ['', '', '']
      }
    });

    const filteredQuestions = computed(() => {
      const questions = state.questions
        .filter(question => {
          if (state.filter === 'Bookmarks') return questionIsBookmarked(fieldValue.value, question);
          if (state.filter === 'My Questions')
            return question?.author === user.value._id.toString();
          return true;
        })
        .reverse();

      if (state.questionInput.trim() === '') return questions;

      const queryKeywords = state.questionInput.trim().toLowerCase().split(' ');

      const matchedQuestions: Array<{ question: string; score: number }> = [];

      questions.forEach(question => {
        const questionKeywords = question.text.trim().toLowerCase().split(' ');

        let score = 0;

        for (let i = 0; i < queryKeywords.length; i++) {
          for (let j = 0; j < questionKeywords.length; j++) {
            const queryKeywordWithoutPunctuation = queryKeywords[i].replace(
              /[.,/#!$%^&*;:{}=\-_`~()]/g,
              ''
            );
            const isInQuestion = questionKeywords[j].includes(queryKeywordWithoutPunctuation);
            const isInQuestionWPunc = questionKeywords[j].includes(queryKeywords[i]);

            if (queryKeywordWithoutPunctuation !== '' && isInQuestion && isInQuestionWPunc) {
              score += 2;
              break;
            } else if (
              (queryKeywordWithoutPunctuation !== '' && isInQuestion) ||
              isInQuestionWPunc
            ) {
              score++;
              break;
            }
          }
        }

        if (score > 0) {
          matchedQuestions.push({ question, score });
        }
      });

      matchedQuestions.sort((a, b) => b.score - a.score);

      return matchedQuestions.map(match => match.question);
    });

    const timeline = computed(() =>
      filteredQuestions.value.slice(
        (state.page - 1) * MAX_QUESTIONS_PER_PAGE,
        (state.page - 1) * MAX_QUESTIONS_PER_PAGE + MAX_QUESTIONS_PER_PAGE
      )
    );

    const numPages = computed(() =>
      Math.ceil(filteredQuestions.value.length / MAX_QUESTIONS_PER_PAGE)
    );

    const maxQuestions = computed(() => {
      return (
        adkData.value?.fields?.find(f => f.model === 'maxQuestions')?.setup?.maxQuestions ||
        adkData.value?.fields?.find(f => f.model === 'maxQuestions')?.preset?.maxQuestions
      );
    });

    const questionsRemaining = computed(() => {
      const teamQuestions =
        teamDoc.value?.adks?.find(a => a.name === props.adkType)?.questionsAsked || [];
      const remaining = maxQuestions.value - teamQuestions.length;
      ctx.emit('remaining', remaining);
      return remaining;
    });

    async function fetchQuestionList() {
      try {
        isFetching.value = true;
        const data = {
          program_id: ctx.root.$route.params.programId || programDoc.value?.data?.program_id,
          public_id: programDoc.value?.data?._id
        };
        const res = await fetchQuestions(data);
        if (res.data) {
          state.questions = getQuestions.value.sort((a: QuestionType, b: QuestionType) =>
            a.likes > b.likes ? 1 : -1
          );
        }
        isFetching.value = false;
      } catch (error) {
        isFetching.value = false;
      }
    }

    fetchQuestionList();

    async function postMyQuestion() {
      try {
        loading.value = true;
        const data = {
          public_id: programDoc?.value?.data?._id,
          program_id: ctx.root.$route.params.programId || programDoc.value?.data?.program_id,
          adk_name: props?.adkType,
          author: user.value?._id,
          text: state.questionInput,
          comments: [],
          likes: 0,
          dislikes: 0,
          flags: 0
        };
        const res = await postQuestion(data);
        if (res.data) {
          state.questionInput = '';
          teamDoc.value = res.data.programTeam;
          if (questionsRemaining.value === 0) {
            ctx.emit('remaining', questionsRemaining.value);
          }
          loading.value = false;
        }
      } catch (error) {
        loading.value = false;
      }
    }

    async function updateMyQuestion(val) {
      try {
        const question = { ...val, public_id: programDoc?.value?.data?._id };
        await updateQuestion(question);
      } catch (error) {
        console.log(error);
      }
    }

    const likeQuestion = async question => {
      const myQue = question;
      if (!fieldValue.value?.likedQuestions) fieldValue.value.likedQuestions = [];

      if (!questionIsLiked(fieldValue.value, myQue)) {
        if (questionIsDisliked(fieldValue.value, myQue)) {
          myQue.dislikes -= 1;
          fieldValue.value.dislikedQuestions = removeId(
            fieldValue.value.dislikedQuestions,
            myQue._id
          );
        }
        myQue.likes += 1;
        fieldValue.value.likedQuestions.push(myQue._id);
        await updateMyQuestion(myQue);
        ctx.emit('update-adk');
      } else {
        myQue.likes -= 1;
        fieldValue.value.likedQuestions = removeId(fieldValue.value.likedQuestions, myQue._id);
        await updateMyQuestion(myQue);
        ctx.emit('update-adk');
      }
    };

    const dislikeQuestion = async question => {
      const myQue = question;
      if (!fieldValue.value?.dislikedQuestions) fieldValue.value.dislikedQuestions = [];

      if (!questionIsDisliked(fieldValue.value, myQue)) {
        if (questionIsLiked(fieldValue.value, myQue)) {
          myQue.likes -= 1;
          fieldValue.value.likedQuestions = removeId(fieldValue.value.likedQuestions, myQue._id);
        }
        myQue.dislikes += 1;
        fieldValue.value.dislikedQuestions.push(myQue._id);
        await updateMyQuestion(myQue);
        ctx.emit('update-adk');
      } else {
        myQue.dislikes -= 1;
        fieldValue.value.dislikedQuestions = removeId(
          fieldValue.value.dislikedQuestions,
          myQue._id
        );
        await updateMyQuestion(myQue);
        ctx.emit('update-adk');
      }
    };

    const flagQuestion = async question => {
      const myQue = question;
      if (!fieldValue.value?.flaggedQuestions) fieldValue.value.flaggedQuestions = [];
      if (!questionIsFlagged(fieldValue.value, myQue)) {
        myQue.flags += 1;
        fieldValue.value.flaggedQuestions.push(myQue._id);
        await updateMyQuestion(myQue);
        ctx.emit('update-adk');
      } else {
        myQue.flags -= 1;
        fieldValue.value.flaggedQuestions = removeId(fieldValue.value.flaggedQuestions, myQue._id);
        await updateMyQuestion(myQue);
        ctx.emit('update-adk');
      }
    };

    const bookmarkQuestion = async question => {
      if (!fieldValue.value?.bookmarkedQuestions) fieldValue.value.bookmarkedQuestions = [];
      if (
        fieldValue.value?.bookmarkedQuestions.some((id: string) => id === question._id.toString())
      ) {
        fieldValue.value.bookmarkedQuestions = removeId(
          fieldValue.value?.bookmarkedQuestions,
          question._id
        );
        await updateMyQuestion(question);
        ctx.emit('update-adk');
      } else {
        fieldValue.value?.bookmarkedQuestions.push(question._id.toString());
        await updateMyQuestion(question);
        ctx.emit('update-adk');
      }
    };

    const postComment = async (questionId, comment) => {
      isCommenting.value = true;
      const question = timeline.value.find(q => q._id === questionId);
      question.comments.push(comment);
      await updateMyQuestion(question);
      ctx.emit('update-adk');
      isCommenting.value = false;
    };

    const likeComment = async (questionId, comment) => {
      const question = timeline.value.find(q => q._id === questionId);
      const myComment = comment;
      if (!fieldValue.value?.likedComments) fieldValue.value.likedComments = [];
      if (!commentIsLiked(fieldValue.value, comment)) {
        myComment.likes += 1;
        fieldValue.value.likedComments.push(myComment._id);
        await updateMyQuestion(question);
        ctx.emit('update-adk');
      } else {
        myComment.likes -= 1;
        state.studentAdkData!.likedComments = removeId(fieldValue.value.likedComments, comment._id);
        await updateMyQuestion(question);
        ctx.emit('update-adk');
      }
    };

    const flagComment = async (questionId, comment) => {
      const question = timeline.value.find(q => q._id === questionId);
      const myComment = comment;
      if (!fieldValue.value?.flaggedComments) fieldValue.value.flaggedComments = [];
      if (!commentIsFlagged(fieldValue.value, comment)) {
        myComment.flags += 1;
        fieldValue.value.flaggedComments.push(myComment._id);
        await updateMyQuestion(question);
        ctx.emit('update-adk');
      } else {
        myComment.flags -= 1;
        fieldValue.value.flaggedComments = removeId(fieldValue.value.flaggedComments, comment._id);
        await updateMyQuestion(question);
        ctx.emit('update-adk');
      }
    };

    return {
      ...toRefs(state),
      questionsRemaining,
      flagComment,
      postComment,
      updateMyQuestion,
      likeComment,
      numPages,
      timeline,
      fieldValue,
      fetchQuestionList,
      postMyQuestion,
      isFetching,
      filterOptions,
      questionIsFlagged,
      scrollUp,
      user,
      teamDoc,
      loading,
      likeQuestion,
      dislikeQuestion,
      bookmarkQuestion,
      flagQuestion,
      isCommenting
    };
  }
});
