
















































import { ref, computed } from '@vue/composition-api';
import store, { useUserActions } from '@/store';
import { ActionTypes } from '@/store/modules/tools/actions';

export default {
  props: {
    value: {
      type: Object,
      default: () => {}
    },
    userType: {
      type: String,
      default: ''
    },
    adkType: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: ''
    },
    isDraft: {
      type: Boolean,
      default: false
    },
    fetchProgram: {
      required: false,
      type: [Object, Function],
      default: () => {}
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const { addMatterPortData } = useUserActions(['addMatterPortData']);
    const validationRules = computed(() => {
      return {
        required: value => !!value || 'This field is required.',
        min: 11,
        max: 11
      };
    });
    const embedUrl = ref();
    const modelIdValue = ref();
    const isLoading = ref(false);
    const saveModelId = async () => {
      isLoading.value = true;
      const payload = {
        document_type: props?.isDraft ? 'ProgramRevision' : 'Program',
        document_id: props?.value?.data?._id?.toString(),
        adk_type: props?.adkType,
        embed_model_id: modelIdValue.value
      };
      const response = await addMatterPortData(payload);
      if (response) {
        props?.fetchProgram();
        isLoading.value = false;
        store.dispatch(`tools/${ActionTypes.showSnackbar}`, {
          message: 'Matterport Id saved successfully!',
          type: 'success',
          isShowSnackbar: true
        });
      }
    };

    const adkObject = computed(() => {
      return props?.value?.data?.adks?.find(adk => adk?.name === props?.adkType);
    });

    const matterPortField = adkObject?.value?.fields?.find(field => field?.name === 'm-matterport');
    if (matterPortField) {
      modelIdValue.value = matterPortField?.setup[matterPortField?.model];
      embedUrl.value = `https://my.matterport.com/show?m=${
        matterPortField?.setup[matterPortField?.model]
      }&play=1`;
    }

    const fields: any = computed(() => {
      if (props?.value?.fields) {
        return props?.value?.fields;
      }
      if (adkObject?.value?.setupRequired === true) {
        return adkObject?.value.fields;
      }
      return adkObject?.value?.presetFields;
    });

    const field = computed(() => {
      if (fields?.value?.length) {
        return fields.value.find(item => item.name === 'm-matterport');
      }
      return false;
    });

    const styling = computed(() => {
      if (field) {
        return field?.value?.styling || props?.value?.styling;
      }
      return false;
    });

    const btnLabel = computed(() => {
      return styling?.value?.btnLabel || 'ADD';
    });

    const textHint = computed(() => {
      return styling?.value?.hint || 'Please make sure it is editable by anyone who views';
    });

    const inputLabel = computed(() => {
      return styling?.value?.label || 'Enter your matterport space ID';
    });

    const headerLabel = computed(() => {
      return styling?.value?.headerLabel || 'Add Matterport ID to render embeded 3D view';
    });

    return {
      validationRules,
      embedUrl,
      modelIdValue,
      isLoading,
      saveModelId,
      btnLabel,
      textHint,
      inputLabel,
      headerLabel
    };
  }
};
