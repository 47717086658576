















import { computed } from '@vue/composition-api';
import { VCheckbox } from 'vuetify/lib';

export default {
  components: {
    VCheckbox
  },
  props: {
    selectedItems: {
      type: Array,
      default: () => []
    },
    errorMessages: {
      type: Array,
      default: () => []
    },
    styling: {
      type: Object,
      default: () => ({})
    },
    items: {
      type: Array,
      default: () => []
    },
    defaultValue: {
      type: Array,
      default: () => []
    }
  },
  setup(props, ctx) {
    console.log(props);
    const selectedItemsValue = computed({
      get: () => props.selectedItems || props.defaultValue || [],
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });

    return { selectedItemsValue };
  }
};
