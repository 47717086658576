


















































































































































































































































































































































































































import { reactive, toRefs, ref, computed, watch } from '@vue/composition-api';
// import gql from 'graphql-tag';
import draggable from 'vuedraggable';
import axios from 'axios';
// import videojs from 'video.js';
import MongoDoc from './types';
import vid from './videorec.vue';

export default {
  name: 'ModuleSetup',
  components: {
    draggable,
    vid
  },

  props: {
    programDoc: {
      required: true,
      type: Object as () => MongoDoc
    },
    fetchProgram: {
      required: true,
      type: [Object, Function]
    },
    formId: {
      required: true,
      type: String
    },
    adkType: {
      required: true,
      type: String
    },
    styling: {
      type: Object,
      required: false,
      default: () => {}
    },
    isVideoask: {
      required: false,
      type: Boolean,
      default: false
    }
  },

  setup(props: any, ctx: any) {
    const setup = reactive({});
    const defaultFormId = ref(process.env.VUE_APP_VIDEOASK_FORM_ID);
    const isFetching = ref(false);
    const programDocument = computed({
      get: () => props.programDoc,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });

    const index = computed(() => {
      const indx = programDocument.value.data.adks.findIndex(obj => {
        if (obj) {
          return obj.name === props.adkType;
        }
        return false;
      });
      return indx;
    });

    const fieldIndex = computed(() => {
      const Fdindx = programDocument.value.data.adks[index.value].fields.findIndex(field => {
        if (field.name) {
          return field.name === 'm-videoask-generation';
        }
        return false;
      });
      return Fdindx;
    });

    // Questions Array
    const interviewQuestions = ref([]);

    // Dialog Handling
    const viewQuestionDialog = ref(false);
    const addQuestionDialog = ref(false);
    const reRecordDialog = ref(false);
    const reRecord = ref<InstanceType<typeof vid>>();
    const addQuestion = ref<InstanceType<typeof vid>>();
    const editInterviewQuestion = ref(false);
    const deleteInterviewQuestion = ref(false);
    const resetInterviewDialog = ref(false);
    const isDeleting = ref(false);
    const renameQuestion = ref('');
    const newQuestion = ref('');
    const currentQuestionId = ref('');
    const updatingQuestion = ref(false);
    const currentMediaUrl = ref('');
    const base64 = ref('');
    const submittingQuestion = ref(false);
    const changeOrderDialog = ref(false);
    const isChanging = ref(false);
    const observer: any = ref('');
    let moveEvent = reactive({
      newIndex: '',
      oldIndex: '',
      element: ''
    });

    function openAddQModal() {
      addQuestion.value?.initPlayer();
      addQuestionDialog.value = true;
    }

    function getMedia(val) {
      base64.value = val;
    }

    const isBase64 = computed(() => {
      if (base64.value) {
        return true;
      }
      return false;
    });

    function reRecordQ(val) {
      reRecord.value?.initPlayer();
      reRecordDialog.value = true;
      currentQuestionId.value = val.question_id;
    }

    function viewQuestion(val) {
      viewQuestionDialog.value = true;
      currentMediaUrl.value = val;
    }

    function change(event) {
      changeOrderDialog.value = true;
      moveEvent = { ...event.moved };
    }

    function revert() {
      if (moveEvent) {
        interviewQuestions.value[moveEvent.newIndex] = interviewQuestions.value[moveEvent.oldIndex];
        interviewQuestions.value[moveEvent.oldIndex] = moveEvent.element;
        changeOrderDialog.value = false;
        moveEvent = reactive({
          newIndex: '',
          oldIndex: '',
          element: ''
        });
      }
    }

    async function changeOrder() {
      try {
        isChanging.value = true;
        const res = await axios.put(
          `${process.env.VUE_APP_ADK_INTERVIEW_REORDER}`,
          {
            question_array: interviewQuestions.value
          },
          {
            params: {
              form_id: props.formId
            },
            headers: {
              'Content-Type': 'application/json'
            }
          }
        );
        interviewQuestions.value = res.data.data.questions;
        changeOrderDialog.value = false;
        isChanging.value = false;
      } catch (err) {
        console.log(err);
      }
    }

    async function fetchFormData(val) {
      try {
        const res = await axios.get(`${process.env.VUE_APP_ADK_INTERVIEW_FORMDATA}`, {
          params: {
            form_id: val
          },
          headers: {
            'Content-Type': 'application/json'
          }
        });
        if (res) {
          interviewQuestions.value = res.data.data.questions;
        }
      } catch (err) {
        console.log(err);
      }
    }

    async function updateQuestion() {
      try {
        updatingQuestion.value = true;
        const data = {
          title: renameQuestion.value
        };
        const res = await axios.patch(`${process.env.VUE_APP_ADK_INTERVIEW_RENAME}`, data, {
          params: {
            question_id: currentQuestionId.value
          }
        });
        fetchFormData(props.formId).then(res => {
          currentQuestionId.value = '';
          updatingQuestion.value = false;
          editInterviewQuestion.value = false;
        });
      } catch (err) {
        console.log(err);
        updatingQuestion.value = false;
        editInterviewQuestion.value = false;
      }
    }

    function editQuestion(val) {
      editInterviewQuestion.value = true;
      renameQuestion.value = val.title;
      currentQuestionId.value = val.question_id;
    }

    function openDeleteModal(val) {
      deleteInterviewQuestion.value = true;
      currentQuestionId.value = val.question_id;
    }

    if (props.formId) {
      fetchFormData(props.formId);
    }
    const isQueAvailable = ref(false);

    async function addNewQuestion() {
      const data = {
        media_url: base64.value,
        title: newQuestion.value
      };

      try {
        submittingQuestion.value = true;
        const resp = await axios.post(`${process.env.VUE_APP_ADK_INTERVIEW_ADD_QUESTION}`, data, {
          params: {
            form_id: props.formId
          }
        });
        fetchFormData(props.formId).then(res => {
          addQuestion.value?.removePlayer();
          submittingQuestion.value = false;
          addQuestionDialog.value = false;
          base64.value = '';
          newQuestion.value = '';
          observer.value.reset();
        });
      } catch (err) {
        console.log(err);
        submittingQuestion.value = false;
      }
    }
    function closeAddQDialog() {
      addQuestion.value?.removePlayer();
      addQuestionDialog.value = false;
      newQuestion.value = '';
      observer.value.reset();
    }

    async function deleteQuestion() {
      try {
        isDeleting.value = true;
        const resp = await axios.delete(`${process.env.VUE_APP_ADK_INTERVIEW_DEL_QUESTION}`, {
          params: {
            question_id: currentQuestionId.value
          }
        });

        if (resp) {
          fetchFormData(props.formId).then(res => {
            currentQuestionId.value = '';
            isDeleting.value = false;
            deleteInterviewQuestion.value = false;
          });
        }
      } catch (err) {
        console.log(err);
      }
    }

    async function reRecordQuestion() {
      const data = {
        media_url: base64.value
      };

      try {
        submittingQuestion.value = true;
        const resp = await axios.patch(`${process.env.VUE_APP_ADK_INTERVIEW_RE_QUESTION}`, data, {
          params: {
            question_id: currentQuestionId.value
          }
        });
        fetchFormData(props.formId).then(() => {
          reRecord.value?.removePlayer();
          submittingQuestion.value = false;
          reRecordDialog.value = false;
          base64.value = '';
          currentQuestionId.value = '';
        });
      } catch (err) {
        console.log(err);
        submittingQuestion.value = false;
      }
    }

    function closeReRecordDialog() {
      reRecord.value?.removePlayer();
      currentQuestionId.value = '';
      reRecordDialog.value = false;
    }

    async function resetInterview() {
      try {
        isDeleting.value = true;
        const resp = await axios.patch(
          `${process.env.VUE_APP_ADK_INTERVIEW_RESET}?old_form_id=${props.formId}&new_form_id=${
            props?.styling?.videoask_form_id || process.env.VUE_APP_VIDEOASK_FORM_ID
          }`
        );
        fetchFormData(props.formId).then(() => {
          isDeleting.value = false;
          resetInterviewDialog.value = false;
        });
      } catch (err) {
        console.log(err);
        isDeleting.value = false;
      }
    }
    const formIdValue: any = computed({
      get: () => props.formId,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });

    watch(formIdValue, async val => {
      if (val) {
        isFetching.value = true;
        await fetchFormData(val);
        isFetching.value = false;
      }
    });

    return {
      viewQuestionDialog,
      isFetching,
      resetInterview,
      addQuestion,
      reRecordDialog,
      changeOrderDialog,
      moveEvent,
      revert,
      closeAddQDialog,
      observer,
      closeReRecordDialog,
      change,
      reRecord,
      isChanging,
      getMedia,
      openAddQModal,
      addQuestionDialog,
      resetInterviewDialog,
      editInterviewQuestion,
      currentMediaUrl,
      interviewQuestions,
      deleteInterviewQuestion,
      editQuestion,
      renameQuestion,
      reRecordQuestion,
      isDeleting,
      programDocument,
      reRecordQ,
      isQueAvailable,
      fetchFormData,
      openDeleteModal,
      deleteQuestion,
      newQuestion,
      index,
      changeOrder,
      updatingQuestion,
      currentQuestionId,
      viewQuestion,
      updateQuestion,
      addNewQuestion,
      submittingQuestion,
      isBase64,
      ...toRefs(setup),
      defaultFormId
    };
  }
};
