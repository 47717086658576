





















































































































































































































































import { useUserActions, useUserGetters } from '@/store';
import { watch, watchEffect, computed, inject, PropType, ref } from '@vue/composition-api';
import AButton from '@/components/atoms/AButton.vue';
import { ValidationObserver } from '@/validation';
import MWeeklyMeeting from '@/components/molecules/MWeeklyMeeting.vue';
import MRegularMeeting from '@/components/molecules/MRegularMeeting.vue';
import MInternsBadge from '@/components/molecules/MInternsBadge.vue';
import MCalendar from '@/components/molecules/MCalendar.vue';
import moment from 'moment';
import MongoDoc from './types';

export default {
  name: 'ModuleSetup',
  components: {
    AButton,
    MInternsBadge,
    MWeeklyMeeting,
    // MDailyCoworking,
    MRegularMeeting,
    MCalendar,
    ValidationObserver
  },
  props: {
    value: {
      required: true,
      type: Object as PropType<MongoDoc>
    },
    userType: {
      required: true,
      type: String
    },
    fetchProgram: {
      required: false,
      type: [Object, Function],
      default: () => {}
    },
    adkIndex: {
      type: Number,
      default: 0
    },
    disabled: {
      type: Boolean,
      default: false
    },
    adkType: {
      type: String,
      default: ''
    },
    mode: {
      type: String,
      default: ''
    }
  },
  setup(props: any, ctx: any) {
    /** actions */
    const { createCalendar } = useUserActions(['createCalendar']);
    const { updateCalendar } = useUserActions(['updateCalendar']);
    const { disconnectUserFromCalendar } = useUserActions(['disconnectUserFromCalendar']);
    const { getUser } = useUserGetters(['getUser']);
    const user: any = computed({
      get: () => getUser.value,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });
    const { getAuthUrl } = useUserActions(['getAuthUrl']);
    const { refreshCalendarTokenData } = useUserActions(['refreshCalendarTokenData']);
    const saveRegularMeetingProgress = ref(false);
    const disableSaveBtn = ref(false);
    const snackbar = ref(false);
    const editWeeklyMeeting = ref(false);
    const snackbarMessge = ref('');
    const internsBadges = ref('');
    const programDoc: any = computed({
      get: () => props.value,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });

    const adkIndexValue: any = computed({
      get: () => props.adkIndex,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });

    const adkObject = computed(() => {
      return programDoc.value?.data?.adks?.find(adk => adk?.name === props?.adkType);
    });

    const organizer = computed(() => {
      return programDoc?.value?.data?.organizers?.find(
        org => org?.userId?.toString() === user?.value?._id?.toString()
      );
    });

    const programFields = computed(() => {
      return adkObject?.value?.fields || programDoc.value.fields;
    });

    const fieldIndex = computed(() => {
      return programFields?.value?.findIndex(obj => {
        if (obj) {
          return obj.name === 'm-meet-adk';
        }
        return false;
      });
    });

    const styling: any = computed(() => {
      const fieldValue = programFields?.value?.[fieldIndex.value]?.styling;
      if (fieldValue) {
        return fieldValue;
      }
      return false;
    });

    const meetHeader = computed(() => {
      if (styling?.value?.meetHeader) {
        return styling.value.meetHeader;
      }
      return false;
    });

    /** flag */
    const isEmployer = ref(false);
    const isSaved = ref(false);
    const saveCalendarProgress = ref(false);
    const readonly = inject('readonly', false);
    const url = ref();
    const simpleMeetingData = ref('');
    const weeklyMeetingData: any = ref('');
    const connectWithGoogleProgress = ref(false);
    const connectWithOutlookProgress = ref(false);
    /** Methods */
    const isScopeUnchecked = ref(false);
    watchEffect(() => {
      if (ctx.root.$route.query.isScopeUnchecked) {
        isScopeUnchecked.value = true;
      }
    });
    function filterAccessTokenAndType() {
      let accessToken;
      let google;
      let refreshToken;
      if (organizer?.value?.googleDetails) {
        accessToken = organizer?.value?.googleDetails?.access_token;
        google = true;
        refreshToken = organizer?.value?.googleDetails?.refresh_token;
      } else if (organizer?.value?.outlookDetails) {
        accessToken = organizer?.value?.outlookDetails?.access_token;
        google = false;
        refreshToken = organizer?.value?.outlookDetails?.refresh_token;
      }
      return { accessToken, google, refreshToken };
    }

    /*     function filterCoworkingEventData(coworkingData) {
      const byDay = coworkingData.daysPerWeekCoworking.filter(value => value);
      const interval = coworkingData.coworkingRepeat.split(' ')[0];
      const count = coworkingData.coworkingOccurence.split(' ')[0];
      let recurrence;
      let timezone;
      if (user.value.googleDetails) {
        timezone = 'America/Los_Angeles';
        recurrence = [`RRULE:FREQ=WEEKLY;INTERVAL=${interval};COUNT=${count};BYDAY=${byDay}`];
      } else if (user.value.outlookDetails) {
        timezone = 'Pacific Standard Time';
        recurrence = {
          pattern: {
            type: 'weekly',
            interval,
            daysOfWeek: coworkingData.daysPerWeekCoworking.filter(value => value)
          },
          range: {
            type: 'numbered',
            startDate: coworkingData.coworkingDate,
            recurrenceTimeZone: timezone,
            numberOfOccurrences: count
          }
        };
      }
      const coworking = {
        pc_event_participants: coworkingData.coworkingSelected.filter(value => value),
        meet_id: 'meet_id',
        summary: coworkingData.coworkingName,
        description: coworkingData.coworkingDescription,
        start_date_time: coworkingData.coworkingDate,
        start_time_zone: timezone,
        end_time_zone: timezone,
        location: coworkingData.workLocation,
        hours_per_day: coworkingData.hoursItem.filter(value => value),
        recurrence,
        reminders: {
          useDefault: false,
          overrides: [
            {
              method: 'popup',
              minutes: 10
            }
          ]
        }
      };
      return coworking;
    } */
    function filterReminder(remindersArray) {
      const noti: any = [
        {
          name: '3 days before',
          method: 'email',
          minutes: 4320
        },
        {
          name: '2 days before',
          method: 'email',
          minutes: 2880
        },
        {
          name: '1 day before',
          method: 'email',
          minutes: 1440
        },
        {
          name: '8 hours before',
          method: 'email',
          minutes: 480
        },
        {
          name: '1 hour before',
          method: 'email',
          minutes: 60
        }
      ];
      const reminders: any = [];
      for (let k = 0; k < remindersArray.length; k++) {
        const element = remindersArray[k];
        for (let i = 0; i < noti.length; i++) {
          if (noti[i].name === element) {
            delete noti[i].name;
            reminders.push(noti[i]);
          }
        }
      }
      return reminders;
    }

    function calculateStartAndEndDateTime(date, time, duration) {
      const startDateTime = moment(`${date} ${time}`).format('YYYY-MM-DDTHH:mm:ss');
      let endDateTime;
      if (duration.includes('Minute')) {
        endDateTime = moment(startDateTime)
          .add(duration.split(' ')[0], 'minutes')
          .format('YYYY-MM-DDTHH:mm:ss');
      } else if (duration.includes('Hour')) {
        endDateTime = moment(startDateTime)
          .add(duration.split(' ')[0], 'hours')
          .format('YYYY-MM-DDTHH:mm:ss');
      }
      return { startDateTime, endDateTime };
    }

    function filterWeeklyMeetingEventData(weeklyMeetingData) {
      const { startDateTime, endDateTime } = calculateStartAndEndDateTime(
        weeklyMeetingData.date,
        weeklyMeetingData.time,
        weeklyMeetingData.duration
      );
      const byDay = weeklyMeetingData.daysPerWeek.filter(value => value);
      const interval = weeklyMeetingData.repeat.split(' ')[0];
      const deadlineDate = weeklyMeetingData.deadlineDate.replace(/-/g, '');
      let recurrence;
      let reminders;
      if (weeklyMeetingData.selectedValue.length >= 1) {
        reminders = {
          useDefault: false,
          overrides: filterReminder(weeklyMeetingData.selectedValue)
        };
      }
      let reminderMinutes;
      let newLocation;
      if (organizer?.value?.googleDetails) {
        recurrence = [
          // `RRULE:FREQ=WEEKLY;INTERVAL=${interval};COUNT=${count};BYDAY=${byDay}`
          `RRULE:FREQ=WEEKLY;INTERVAL=${interval};UNTIL=${deadlineDate};BYDAY=${byDay}`
        ];
        newLocation =
          (weeklyMeetingData.googleMeet.length < 1
            ? weeklyMeetingData.digitalLocation
            : undefined) ||
          (weeklyMeetingData.physicalLocation ? weeklyMeetingData.physicalLocation : undefined);
      } else if (organizer?.value?.outlookDetails) {
        newLocation = weeklyMeetingData.digitalLocation || weeklyMeetingData.physicalLocation;
        if (weeklyMeetingData.reminderMinutes.includes('hour')) {
          reminderMinutes = weeklyMeetingData.reminderMinutes.split(' ')[0] * 60;
        } else if (weeklyMeetingData.reminderMinutes.includes('day')) {
          reminderMinutes = weeklyMeetingData.reminderMinutes.split(' ')[0] * 60 * 24;
        } else {
          reminderMinutes = weeklyMeetingData.reminderMinutes.split('')[0] * 1;
        }
        recurrence = {
          pattern: {
            type: 'weekly',
            interval,
            daysOfWeek: weeklyMeetingData.daysPerWeek.filter(value => value)
          },
          range: {
            type: 'endDate',
            startDate: weeklyMeetingData.date,
            endDate: weeklyMeetingData.deadlineDate
          }
        };
      }
      const weeklyMeeting = {
        pc_event_participants: weeklyMeetingData.selected.filter(value => value),
        meet_id: weeklyMeetingData.googleMeet.length < 1 ? undefined : 'meet_id',
        summary: weeklyMeetingData.meetingName,
        description: weeklyMeetingData.description,
        start_date_time: startDateTime,
        start_time_zone: weeklyMeetingData.timeZone,
        end_date_time: endDateTime,
        end_time_zone: weeklyMeetingData.timeZone,
        recurrence,
        reminders,
        reminderMinutes,
        location: newLocation
      };
      return weeklyMeeting;
    }

    async function saveCalendar() {
      saveCalendarProgress.value = true;
      const { accessToken, google, refreshToken } = filterAccessTokenAndType();
      const eventData = {
        meeting_type: 'weekly_coworking_meeting',
        program_id: props.value.data._id.toString(),
        weekly_meeting: filterWeeklyMeetingEventData(weeklyMeetingData.value),
        adkName: adkObject.value?.name
        // coworking: filterCoworkingEventData(coworkingData.value)
      };
      let response;
      if (
        programFields.value[fieldIndex.value].setup.events &&
        programFields.value[fieldIndex.value].setup.events?.weekly_meeting
      ) {
        const eventId = programFields.value[fieldIndex.value].setup.events?.weekly_meeting?.id;
        response = await updateCalendar({
          eventId,
          accessToken,
          google,
          refreshToken,
          eventData,
          adkName: adkObject.value?.name
        });
        if (response) {
          editWeeklyMeeting.value = false;
          saveCalendarProgress.value = false;
          props.fetchProgram();
          isEmployer.value = false;
        }
      } else {
        response = await createCalendar({
          accessToken,
          google,
          refreshToken,
          eventData,
          adkName: adkObject.value?.name
        });
        if (response) {
          saveCalendarProgress.value = false;
          props.fetchProgram();
          isEmployer.value = false;
        }
      }
      saveCalendarProgress.value = false;
    }

    function getSimpleMeetingEmitedData(value) {
      simpleMeetingData.value = value;
    }

    function getWeeklyMeetingEmitData(value) {
      weeklyMeetingData.value = value;
      if (weeklyMeetingData.value?.selected.length < 1) {
        disableSaveBtn.value = true;
      } else {
        disableSaveBtn.value = false;
      }
    }

    function getInternsBadge(data) {
      internsBadges.value = data.value;
    }
    /*     function getCoworkingEmitData(value) {
      coworkingData.value = value;
    } */

    function filterSimpleMeetingData(simpleMeetingData) {
      const startTime = simpleMeetingData.startAndEndTime.split('-')[0];
      const endTime = simpleMeetingData.startAndEndTime.split('-')[1];
      const simpleMeetingStartDateTime = `${simpleMeetingData.simpleMeetingStartDate}T${moment(
        startTime,
        ['h:mm A']
      ).format('HH:mm:ss')}`;
      const simpleMeetingEndDateTime = `${simpleMeetingData.simpleMeetingEndDate}T${moment(
        endTime,
        ['h:mm A']
      ).format('HH:mm:ss')}`;
      const pc_event_participants = simpleMeetingData.simpleMeetingSelected;
      const simple_meeting: any = {
        pc_event_participants: pc_event_participants.filter(value => value),
        meet_id: 'meet_id',
        summary: simpleMeetingData.simpleMeetingName,
        description: simpleMeetingData.simpleMeetingDescription,
        start_date_time: simpleMeetingStartDateTime,
        end_date_time: simpleMeetingEndDateTime,
        start_time_zone: simpleMeetingData.simpleMeetingTimeZone,
        end_time_zone: simpleMeetingData.simpleMeetingTimeZone,
        reminders: {
          useDefault: false,
          overrides: [
            {
              method: 'popup',
              minutes: 10
            }
          ]
        }
      };
      if (
        (simpleMeetingData.daysPerWeekForSimpleMeeting.length >= 1 &&
          simpleMeetingData.simpleMeetingRepeat !== undefined,
        simpleMeetingData.endsAfter !== undefined)
      ) {
        const byDay = simpleMeetingData.daysPerWeekForSimpleMeeting.filter(value => value);
        const interval = simpleMeetingData.simpleMeetingRepeat.split(' ')[0];
        const count = simpleMeetingData.endsAfter.split(' ')[0];
        let recurrence;
        if (organizer?.value?.googleDetails) {
          recurrence = [`RRULE:FREQ=WEEKLY;INTERVAL=${interval};COUNT=${count};BYDAY=${byDay}`];
        } else if (organizer?.value?.outlookDetails) {
          recurrence = {
            pattern: {
              type: 'weekly',
              interval,
              daysOfWeek: simpleMeetingData.daysPerWeekForSimpleMeeting.filter(value => value)
            },
            range: {
              type: 'numbered',
              startDate: simpleMeetingData.simpleMeetingStartDate,
              recurrenceTimeZone: simpleMeetingData.simpleMeetingTimeZone,
              numberOfOccurrences: count
            }
          };
        }
        simple_meeting.recurrence = recurrence;
      }
      const eventData = {
        meeting_type: 'simple_meeting',
        program_id: props.value.data._id.toString(),
        simple_meeting
      };
      return eventData;
    }

    async function saveRegularMeeting() {
      saveRegularMeetingProgress.value = true;
      const { accessToken, google } = filterAccessTokenAndType();
      const eventData = filterSimpleMeetingData(simpleMeetingData.value);
      const response = await createCalendar({
        accessToken,
        google,
        eventData,
        adkName: adkObject.value?.name
      });
      if (response) {
        snackbarMessge.value = response.message;
        snackbar.value = true;
        isEmployer.value = false;
        saveRegularMeetingProgress.value = false;
      }
      saveRegularMeetingProgress.value = false;
    }

    async function refreshAccessToken(refreshToken, type) {
      const response = await refreshCalendarTokenData({
        refreshToken,
        type,
        document_id: props?.value?.data?._id?.toString(),
        document_type: 'Program',
        adk_type: adkObject?.value?.name
      });
      if (response === undefined) {
        await disconnectUserFromCalendar({
          document_type: 'Program',
          document_id: props?.value?.data?._id?.toString()
        });
        props.fetchProgram();
      }
      return response;
    }

    function editWeeklyMeet() {
      editWeeklyMeeting.value = true;
    }
    const isDisconnect = ref(false);
    async function disconnect() {
      isDisconnect.value = true;
      await disconnectUserFromCalendar({
        document_type: 'Program',
        document_id: props?.value?.data?._id?.toString()
      });
      props.fetchProgram();
      setTimeout(() => {
        isDisconnect.value = false;
      }, 2000);
    }
    const programIdValue: any = computed(() => {
      if (props.userType === 'organizer' || props.userType === 'employer') {
        return props.value.data._id.toString();
      }
      return props.value.data.program_id.toString();
    });
    async function connectWithGoogleOrOutlook(type) {
      if (type === 'google') {
        connectWithGoogleProgress.value = true;
      } else if (type === 'outlook') {
        connectWithOutlookProgress.value = true;
      }
      const response = await getAuthUrl(type);
      localStorage.setItem('programId', programIdValue.value.toString());
      localStorage.setItem('documentId', props.value.data._id.toString());
      localStorage.setItem('guideAdkIndex', adkIndexValue?.value);
      localStorage.setItem('adkType', adkObject?.value?.name);
      localStorage.setItem('connectionType', type);
      localStorage.setItem('isEmployer', 'isEmployer');
      url.value = response;
      if (type === 'google') {
        connectWithGoogleProgress.value = false;
      } else if (type === 'outlook') {
        connectWithOutlookProgress.value = false;
      }
    }
    /** Watcher */
    watch(
      () => url.value,
      urlData => {
        if (urlData !== '') {
          window.open(urlData, '_self');
          url.value = '';
        }
      }
    );
    const google = ref(false);
    const tokenData = ref();
    /** Created */
    if (organizer?.value?.googleDetails) {
      google.value = true;
      tokenData.value = organizer?.value?.googleDetails;
      refreshAccessToken(organizer?.value?.googleDetails?.refresh_token, 'google');
    } else if (organizer?.value?.outlookDetails) {
      google.value = false;
      tokenData.value = organizer?.value?.outlookDetails;
      refreshAccessToken(organizer?.value?.outlookDetails?.refresh_token, 'outlook');
    }

    function goToCalendar() {
      if (organizer?.value?.outlookDetails) {
        window.open('https://outlook.live.com/calendar', '_blank');
      } else {
        window.open('https://calendar.google.com/calendar', '_blank');
      }
    }

    return {
      adkObject,
      meetHeader,
      programFields,
      readonly,
      user,
      isEmployer,
      isSaved,
      simpleMeetingData,
      programDoc,
      snackbar,
      snackbarMessge,
      saveCalendarProgress,
      saveRegularMeetingProgress,
      connectWithGoogleProgress,
      connectWithOutlookProgress,
      disableSaveBtn,
      editWeeklyMeeting,
      internsBadges,
      editWeeklyMeet,
      connectWithGoogleOrOutlook,
      saveCalendar,
      saveRegularMeeting,
      getSimpleMeetingEmitedData,
      getWeeklyMeetingEmitData,
      getInternsBadge,
      isScopeUnchecked,
      disconnect,
      isDisconnect,
      fieldIndex,
      goToCalendar,
      tokenData,
      google,
      organizer
    };
  }
};
