














































































































































import { computed, ref, watch } from '@vue/composition-api';
import _, { findIndex } from 'lodash';
import axios from 'axios';

export default {
  props: {
    value: {
      required: false,
      type: Object,
      default: () => {}
    },
    fetchProgram: {
      type: Function,
      default: () => {}
    },
    type: {
      type: String,
      required: false,
      default: ''
    },
    color: {
      type: String,
      default: ''
    },
    mode: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    adkIndex: {
      type: Number,
      default: 0
    }
  },

  setup(props, ctx) {
    const programDoc: any = computed({
      get: () => props.value,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });

    const adkIndexValue: any = computed({
      get: () => props.adkIndex,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });

    const adkObject = computed(() => {
      return programDoc.value?.data?.adks?.[adkIndexValue.value];
    });

    const adkData = computed(() => {
      let programValue;
      if (props.mode === 'build') {
        programValue = programDoc.value;
      } else {
        programValue = adkObject.value;
      }
      return programValue;
    });

    const fieldIndex = computed(() => {
      return adkData.value?.fields?.findIndex(f => f.name === 'metagrabber');
    });

    const fieldValue = computed({
      get: () => adkData.value?.fields[fieldIndex.value],
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });

    const link_type = computed(() => {
      if (fieldValue) {
        const { model } = fieldValue.value;
        return model === 'researchLinks' ? 'resource' : 'video';
      }
      return false;
    });

    function setInitialData() {
      if (
        link_type.value === 'resource' &&
        (!fieldValue.value.setup?.researchLinks ||
          fieldValue.value.setup?.researchLinks?.length < 1)
      ) {
        const research = {
          resource: '',
          link: '',
          required: true
        };
        fieldValue.value.setup.researchLinks = [{ ...research }];
      }
    }

    watch(props, val => {
      if (val.type) {
        setInitialData();
      }
    });
    setInitialData();

    const linkList = computed({
      get: () => {
        if (link_type.value === 'resource') {
          return fieldValue.value.setup?.researchLinks;
        }
        return fieldValue.value.setup?.videoLinks;
      },
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });

    function populate() {
      const data = ref({
        resource: '',
        link: '',
        unlocked: false,
        completed: false
      });
      const research = ref({
        resource: '',
        link: '',
        required: true,
        unlocked: false,
        completed: false
      });
      if (link_type.value !== 'resource') {
        fieldValue.value.setup?.videoLinks?.push(data.value);
      } else {
        fieldValue.value.setup?.researchLinks?.push(research.value);
      }
      ctx.emit('get-data', programDoc.value);
    }

    function removeIndex(indx) {
      if (link_type.value === 'resource') {
        fieldValue.value.setup?.researchLinks?.splice(indx, 1);
      } else if (link_type.value === 'video') {
        fieldValue.value.setup?.videoLinks?.splice(indx, 1);
      }
    }

    const isSearching = ref(false);
    const currentIndex = ref(-1);
    const fetchMetaInfo = _.debounce(async (val, indx) => {
      currentIndex.value = indx;
      try {
        isSearching.value = true;
        const resp = await axios.get(`${process.env.VUE_APP_META_GRABBER_ENDPOINT}`, {
          params: {
            url: val
          },
          headers: {
            'Content-Type': 'application/json'
          }
        });
        if (resp.data.data) {
          if (link_type.value === 'video') {
            fieldValue.value.setup.videoLinks[indx].name = resp.data.data.title;
          } else if (link_type.value === 'resource') {
            fieldValue.value.setup.researchLinks[indx].resource = resp.data.data.title;
          } else {
            fieldValue.value.setup.researchLinks[indx].resource = resp?.data?.data?.title;
          }
        }
        isSearching.value = false;
      } catch (err) {
        console.log(err);
        isSearching.value = false;
      }
    }, 500);

    return {
      link_type,
      adkIndexValue,
      processingMsg: 'Processing ....',
      fetchMetaInfo,
      programDoc,
      isSearching,
      fieldValue,
      currentIndex,
      linkList,
      populate,
      removeIndex,
      setInitialData
    };
  }
};
