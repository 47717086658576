<template>
  <v-divider :class="styling.class ? styling.class : null"></v-divider>
</template>

<script>
export default {
  name: 'ADivider',
  props: {
    styling: {
      type: Object,
      default: () => {}
    }
  }
};
</script>
