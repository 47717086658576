var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"width":"100%"}},[_vm._l((_vm.linkList),function(i,linkIndex){return _c('div',{key:linkIndex,staticClass:"module-edit__inputs"},[(!i.is_processing)?_c('div',{staticClass:"module-edit__inputs-link"},[_c('validation-provider',{attrs:{"rules":{
          required: true
        },"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"disabled":_vm.disabled === true ? true : i.is_processing,"rounded":"","label":_vm.fieldValue.linkLabel
              ? _vm.fieldValue.linkLabel
              : _vm.link_type === 'video'
              ? 'Video link'
              : 'Enter link',"outlined":""},on:{"input":function($event){return _vm.fetchMetaInfo(i.link, linkIndex)}},model:{value:(i.link),callback:function ($$v) {_vm.$set(i, "link", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"i.link"}})]}}],null,true)})],1):_c('div',{staticClass:"module-edit__inputs-link"},[_c('validation-provider',{attrs:{"rules":{
          required: true
        },"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"disabled":_vm.disabled === true ? true : i.is_processing,"rounded":"","label":_vm.fieldValue.linkLabel
              ? _vm.fieldValue.linkLabel
              : _vm.link_type === 'video'
              ? 'Video link'
              : 'Enter link',"outlined":""},model:{value:(_vm.processingMsg),callback:function ($$v) {_vm.processingMsg=$$v},expression:"processingMsg"}})]}}],null,true)})],1),_c('div',{staticClass:"module-edit__inputs-video",staticStyle:{"margin-right":"0%","margin-left":"1%"}},[_c('validation-provider',{attrs:{"slim":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [(_vm.link_type === 'video')?_c('v-text-field',{attrs:{"disabled":_vm.disabled === true ? true : i.is_processing,"error-messages":errors,"rounded":"","label":_vm.fieldValue.videoName
              ? _vm.fieldValue.videoName
              : _vm.link_type === 'video'
              ? 'Video Name'
              : 'Resource Name',"outlined":"","loading":_vm.isSearching && _vm.currentIndex === linkIndex},scopedSlots:_vm._u([{key:"append",fn:function(){return [(_vm.isSearching && _vm.currentIndex === linkIndex)?_c('v-progress-circular',{attrs:{"indeterminate":"","width":"3","size":"20"}}):_vm._e()]},proxy:true}],null,true),model:{value:(i.name),callback:function ($$v) {_vm.$set(i, "name", $$v)},expression:"i.name"}}):_c('v-text-field',{attrs:{"disabled":_vm.disabled === true ? true : i.is_processing,"error-messages":errors,"rounded":"","label":_vm.fieldValue.videoName
              ? _vm.fieldValue.videoName
              : _vm.link_type === 'video'
              ? 'Video Name'
              : 'Resource Name',"outlined":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [(_vm.isSearching && _vm.currentIndex === linkIndex)?_c('v-progress-circular',{attrs:{"size":"10","color":"green"}}):_vm._e()]},proxy:true}],null,true),model:{value:(i.resource),callback:function ($$v) {_vm.$set(i, "resource", $$v)},expression:"i.resource"}})]}}],null,true)})],1),_c('div',{staticClass:"module-edit__inputs-required"},[(_vm.link_type === 'resource')?_c('v-checkbox',{staticClass:"mr-2",class:_vm.$vuetify.breakpoint.xs ? 'mt-0' : ' ',attrs:{"disabled":_vm.disabled === true ? true : false,"label":"Required?"},model:{value:(i.required),callback:function ($$v) {_vm.$set(i, "required", $$v)},expression:"i.required"}}):_vm._e(),_c('v-btn',{attrs:{"disabled":_vm.linkList.length === 1,"rounded":"","x-large":"","outlined":""},on:{"click":function($event){_vm.$emit('delete', linkIndex);
          _vm.removeIndex(linkIndex);}}},[_vm._v(" Delete ")])],1)])}),(_vm.link_type !== 'video')?_c('v-btn',{staticClass:"white--text",attrs:{"rounded":"","disabled":_vm.disabled === true ? true : false,"x-large":"","color":_vm.color},on:{"click":_vm.populate}},[_c('v-icon',{staticClass:"module-edit__add-icon font-weight-bold",attrs:{"left":""}},[_vm._v(" mdi-plus ")]),_vm._v(" "+_vm._s(_vm.fieldValue.addButtonLabel || 'Add New Link')+" ")],1):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }