































































/* eslint-disable no-prototype-builtins */
/* eslint-disable vue/no-mutating-props */
/* eslint-disable no-param-reassign */
import {
  ref,
  defineComponent,
  computed,
  watchEffect,
  onUnmounted,
  watch,
  onMounted
} from '@vue/composition-api';
import axios from 'axios';
// eslint says it can't find these modules from @types/node
// can be fixed by removing "./node_modules/@types" from typeRoots in tsconfig.json
// but then you get errors for unit test related imports
// anyway this doesn't matter since it will be server-side
import { useUserState } from '@/store';
import Table from './m-timesheet-table.vue';

export default defineComponent({
  name: 'ModuleDefault',
  components: {
    Table
  },
  props: {
    value: {
      required: true,
      type: Object
    },
    fetchProgram: {
      type: Function,
      default: () => {}
    },
    isMonitor: {
      type: Boolean,
      default: false
    },
    adkType: {
      type: String,
      default: ''
    }
  },
  setup(props, ctx) {
    const showInstructions = ref(true);
    const { user } = useUserState(['user']);
    const setupInstructions = ref({
      description: '',
      instructions: ['', '', '']
    });
    const isClockIn = ref(false);
    const isClockOut = ref(false);
    const table = ref();
    const programDoc = computed({
      get: () => props.value,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });

    const adk = ref(programDoc.value?.data?.adks?.find(a => a.name === 'timesheet'));

    const fieldIndex = ref(adk.value?.fields?.findIndex(f => f.name === 'm-timesheet-default'));

    const fieldValue = ref(adk.value?.fields?.find(f => f.name === 'm-timesheet-default')?.value);
    async function checkStatus() {
      try {
        const API_ENDPOINT = process.env.VUE_APP_TIMESHEET_STATUS;
        const res = await axios.get(
          `${API_ENDPOINT}?student_id=${programDoc.value.data?._id.toString()}`,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
            }
          }
        );
        const status = res?.data?.data;
        if (status === 'clock_in') {
          isClockOut.value = true;
          isClockIn.value = false;
        }
        if (status === 'clock_out' || !status.length) {
          isClockIn.value = true;
          isClockOut.value = false;
        }
      } catch (error) {
        console.log(error);
      }
    }
    onMounted(() => {
      watch(isClockIn, val => {
        if (val) {
          table.value.getTableData();
          table.value.getInteractionList();
        }
      });
      watch(isClockOut, val => {
        if (val) {
          table.value.getTableData();
          table.value.getInteractionList();
        }
      });
    });
    const fetching = ref(false);
    watchEffect(async () => {
      if (programDoc.value.data?._id) {
        fetching.value = true;
        await checkStatus();
        fetching.value = false;
      }
    });
    const intervalId = window.setInterval(() => {
      checkStatus();
    }, 15000);
    onUnmounted(() => {
      clearInterval(intervalId);
    });
    const isTimeSheetAvailable = ref(false);
    watchEffect(() => {
      if (programDoc.value.data && programDoc.value.data?.data?.adks) {
        const Index = programDoc.value.data?.data?.adks?.findIndex(a => a.name === 'timesheet');
        if (Index !== -1) {
          const data = programDoc.value.data?.data?.adks[Index];
          if (data?.timesheetDetails) isTimeSheetAvailable.value = true;
        }
      }
    });

    const getVideoAskUrl = url => {
      return `${url}#contact_name=${encodeURI(user?.value?.firstName)}&contact_email=${encodeURI(
        user?.value?.email
      )}&contact_phone_number=${encodeURI(user?.value?.phoneNumber)}&program_id=${encodeURI(
        programDoc?.value?.data?.program_id
      )}&participant_id=${encodeURI(user?.value?._id)}&adk_name=${encodeURI(props.adkType)}`;
    };

    return {
      showInstructions,
      isTimeSheetAvailable,
      setupInstructions,
      user,
      checkStatus,
      isClockIn,
      isClockOut,
      fetching,
      table,
      programDoc,
      getVideoAskUrl
    };
  }
});
