<template>
  <div :class="styling.class ? styling.class : null">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'AHeading',
  props: {
    styling: {
      type: Object,
      default: () => {}
    }
  }
};
</script>
