
























import { computed } from '@vue/composition-api';

export default {
  props: {
    id: {
      type: [String, Number],
      default: 'id'
    },
    value: {
      type: [String, Number, Boolean],
      default: ''
    },
    defaultClasses: {
      type: String,
      default: ''
    },
    errorMessages: {
      type: Array,
      default: () => {}
    },
    singleLine: {
      type: Boolean,
      default: false
    },
    outlined: {
      type: Boolean,
      default: false
    },
    fullWidth: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    offIcon: {
      type: String,
      default: '$checkboxOff'
    },
    onIcon: {
      type: String,
      default: '$checkboxOn'
    },
    dense: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    backgroundColor: {
      type: String,
      default: ''
    },
    appendIcon: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: ''
    },
    dark: {
      type: Boolean,
      default: false
    },
    light: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    styling: {
      type: Object,
      default: () => {}
    },
    defaultValue: {
      type: [String, Number, Boolean],
      default: ''
    }
  },
  setup(props, ctx) {
    const inputValue = computed({
      get: () => props.value || props.defaultValue,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });
    return { inputValue };
  }
};
