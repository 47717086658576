

















































































































/* eslint-disable no-prototype-builtins */
/* eslint-disable vue/no-mutating-props */
/* eslint-disable no-param-reassign */
import { defineComponent, ref, computed, watch } from '@vue/composition-api';
import axios from 'axios';
import type MongoDoc from './types';

export default defineComponent({
  name: 'VideoLinksViewer',
  props: {
    value: {
      required: false,
      type: Object as () => MongoDoc
    },
    adkType: {
      type: String,
      default: ''
    },
    isSaving: {
      type: Boolean,
      default: false
    },
    isUpdating: {
      type: Boolean,
      default: false
    },
    isMonitor: {
      type: Boolean,
      default: false
    },
    isPreview: {
      type: Boolean,
      default: false
    },
    mode: {
      required: false,
      type: String,
      default: ''
    }
  },
  setup(props: any, ctx) {
    const programDoc = computed({
      get: () => props.value,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });

    const adkData = computed(() => {
      return programDoc.value?.data?.adks?.find(a => a.name === props.adkType);
    });

    const fieldValue = computed({
      get: () => adkData.value?.fields?.find(f => f.name === 'metagrabber')?.value,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });

    const fieldIndex = computed(() => {
      return adkData.value?.fields?.findIndex(f => f.name === 'metagrabber');
    });

    const dataLinks = computed({
      get: () => {
        if (props.isPreview) return adkData.value?.fields[fieldIndex.value]?.setup?.videoLinks;
        return fieldValue.value?.trainProgress;
      },
      set: newVal => {
        fieldValue.value = newVal;
      }
    });
    const isCompleted = ref(true);
    const isNotCompletedLinks = dataLinks?.value?.filter(videoLink => !videoLink.completed);
    if (isNotCompletedLinks && isNotCompletedLinks.length >= 1) {
      isCompleted.value = false;
    }
    watch(dataLinks, value => {
      if (value) {
        const isNotCompletedLinks = value.filter(videoLink => !videoLink.completed);
        if (isNotCompletedLinks && isNotCompletedLinks.length >= 1) {
          isCompleted.value = false;
        } else {
          isCompleted.value = true;
        }
      }
    });
    if (dataLinks.value?.length) dataLinks.value[0].unlocked = true;
    // dataLinks.value[0].newCandidate = false;
    const panel = dataLinks.value?.findIndex((obj: any) => obj.unlocked && !obj.completed);

    const finishButtonDisabled = ref(
      dataLinks.value?.every((item: any) => (item.completed ? true : null))
    );
    function videoComplete(index: number) {
      // dataLinks.value[index].newCandidate = false;
      if (dataLinks.value[index + 1]) {
        dataLinks.value[index + 1].unlocked = !dataLinks.value[index + 1].unlocked;
      }
      if (!dataLinks.value[index].completed && dataLinks.value[index + 1]) {
        for (let i = index; i < dataLinks.value.length - 1; i += 1) {
          dataLinks.value[i + 1].unlocked = false;
          dataLinks.value[i + 1].completed = false;
        }
      }
      const lastVideoLink = dataLinks.value[dataLinks.value.length - 1];
      if (lastVideoLink.completed && lastVideoLink.unlocked) {
        finishButtonDisabled.value = true;
      } else {
        finishButtonDisabled.value = false;
      }
    }

    const isLoading = ref(false);

    // async function fetchFormData() {
    //   isLoading.value = true;
    //   const API_ENDPOINT = process.env.VUE_APP_TRAINADK_NEW_BADGE;
    //   const dataValue = props.studentDoc?._id.toString();
    //   try {
    //     const res = await axios.post(
    //       `${API_ENDPOINT}?program_id=${ctx.root.$route.params.programId}&student_id=${dataValue}`,
    //       {
    //         headers: {
    //           'Content-Type': 'application/json'
    //         }
    //       }
    //     );
    //     dataLinks.value[0].unlocked = true;
    //     dataLinks.value[0].newCandidate = false;
    //     isLoading.value = false;
    //   } catch (err) {
    //     console.log(err);
    //   }
    // }

    // fetchFormData();

    function getIframeUrl(url: string) {
      const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
      const match = url.match(regExp);
      if (match && match[7].length === 11) {
        return `https://www.youtube.com/embed/${match[7]}`;
      }
      return url;
    }
    if (
      dataLinks.value?.filter(obj => obj.completed === true).length > 0 &&
      dataLinks.value?.filter(obj => obj.completed === true).length !== dataLinks.value.length
    ) {
      const trainDataValue = dataLinks.value?.filter(obj => obj.completed === true).length;
      dataLinks.value[trainDataValue].unlocked = true;
    }

    return {
      finishButtonDisabled,
      // fetchFormData,
      isLoading,
      getIframeUrl,
      videoComplete,
      panel,
      dataLinks,
      adkData,
      fieldValue,
      isCompleted
    };
  }
});
