


















import _ from 'lodash';
import { computed } from '@vue/composition-api';

export default {
  props: {
    value: {
      type: [String, Number, Array],
      default: () => []
    },
    label: {
      type: String,
      default: ''
    },
    tickLabels: {
      type: [Array, String, Number],
      default: () => []
    },
    min: {
      type: String,
      default: ''
    },
    max: {
      type: String,
      default: ''
    },
    ticks: {
      type: [Boolean, String],
      default: false
    },
    tickSize: {
      type: [Number, String],
      default: 0
    },
    defaultClasses: {
      type: String,
      default: ''
    },
    styling: {
      type: Object,
      default: () => {}
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },

  setup(props: any, ctx) {
    const inputValue = computed({
      get: () => props.value,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });
    const tickLabelsValue = computed(() => {
      return _.range(props.min, props.max);
    });
    const inputVal = computed(() => {
      return inputValue.value || [props.min, props.max];
    });
    return { inputVal, tickLabelsValue };
  }
};
