















































































































































































































































import {
  defineComponent,
  computed,
  ref,
  watch,
  watchEffect,
  onMounted
} from '@vue/composition-api';
import store, { useUserGetters, useUserActions } from '@/store';
import { ActionTypes } from '@/store/modules/tools/actions';
import _ from 'lodash';
import moment from 'moment';

export default defineComponent({
  name: 'CheckoutForm',
  components: {
    // ADatePicker: () => import('@/components/atoms/ADatePicker.vue'),
    ABtn: () => import('@/components/atoms/AButton.vue'),
    // MVerifyPhoneDialog: () => import('@/components/molecules/m-verify-phone-dialog.vue'),
    // MVerifyEmailDialog: () => import('@/components/molecules/m-verify-email-dialog.vue'),
    MUseToken: () => import('@/components/molecules/m-use-token.vue')
  },
  props: {
    value: {
      type: Object,
      default: () => {}
    },
    fetchProgram: {
      type: Function,
      default: () => {}
    },
    updateAdk: {
      type: Function,
      default: () => {}
    },
    isMonitor: {
      type: Boolean,
      default: false
    },
    isNotAuthenticated: {
      type: Boolean,
      default: false
    }
  },
  setup(props, ctx) {
    const { getUser } = useUserGetters(['getUser']);
    const { getPermissionsByRole, updateAdkData, checkoutFreeProgram } = useUserActions([
      'getPermissionsByRole',
      'updateAdkData',
      'checkoutFreeProgram'
    ]);
    const user: any = computed({
      get: () => getUser.value,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });
    const useToken: any = ref();
    const formattedNumber = ref('');
    const verifyNumberDialog = ref(false);
    const isSetting = ref(false);
    const msg = ref('');
    const externDialog = ref(false);
    const verificationCode = ref('');
    const birthdate = ref('');
    const checkoutFormCompleted = ref(false);
    const checkoutQualification = ref(false);
    const phoneNumber = ref();
    const externShipCheckout = ref(false);
    const birthDate = ref(user.value?.birthDate);
    const phoneVerification: any = ref();
    const emailVerification = ref();
    const phone = ref(user.value?.phoneNumber?.substring(2));
    const email = ref(user.value?.email?.toString());
    const tokenDescDialog = ref(false);

    const programDoc = computed({
      get: () => props.value,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });

    const permissions = computed({
      get: () => localStorage?.getItem('permissions')?.split(',') || [],
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });

    const checkOutPermissions = ref([]);

    if (programDoc.value?.data?.checkoutQualification?.roles?.length >= 1) {
      watch(checkOutPermissions, val => {
        if (
          !val?.includes('can_checkout_program') &&
          !permissions?.value?.includes('can_checkout_program')
        ) {
          store.dispatch(`tools/${ActionTypes.showSnackbar}`, {
            message: "you don't have permission to checkout program",
            type: 'error',
            isShowSnackbar: true
          });
        }
      });
    }

    const checkAgeLimit = () => {
      const age = moment().diff(user?.value?.birthDate, 'years');
      const ageInRange = _.inRange(
        age,
        programDoc?.value?.data?.checkoutQualification?.ageLimit[0],
        programDoc?.value?.data?.checkoutQualification?.ageLimit[1]
      );
      if (ageInRange) {
        return true;
      }
      return false;
    };

    const checkoutQualificationErrors: any = ref([]);
    const isVisible = ref(true);

    const isCheckOutPermission = (permissionsData, userValue, programDocValue) => {
      let flag = true;
      const errorMessages: any = [];
      if (userValue && !localStorage.getItem('apollo-token')) {
        flag = true;
      } else if (userValue && localStorage.getItem('apollo-token')) {
        if (!permissionsData.includes('can_checkout_program')) {
          flag = false;
          errorMessages.push("your role doesn't have permission to checkout the program.");
        }
        if (
          programDocValue?.data?.checkoutQualification &&
          programDocValue?.data?.checkoutQualification?.emailDomain &&
          !userValue?.email?.includes(programDocValue?.data?.checkoutQualification?.emailDomain)
        ) {
          flag = false;
          errorMessages.push(
            `Your email must be from ${programDocValue?.data?.checkoutQualification?.emailDomain} domain to checkout the program.`
          );
        }
        if (
          userValue?.userTypes?.includes('Student') &&
          programDocValue?.data?.checkoutQualification?.ageLimit &&
          programDocValue?.data?.checkoutQualification?.ageLimit?.length >= 1
        ) {
          const isCheckAgeLimit = checkAgeLimit();
          if (!isCheckAgeLimit) {
            const min = programDocValue?.data?.checkoutQualification?.ageLimit[0];
            const max = programDocValue?.data?.checkoutQualification?.ageLimit[1];
            flag = false;
            errorMessages.push(
              `Your age must be between ${min} to ${max} years to checkout the program.`
            );
          }
        }
        if (
          userValue?.userTypes?.includes('Student') &&
          userValue?.portfolios?.Student &&
          programDocValue?.data?.checkoutQualification?.city &&
          userValue?.portfolios?.Student?.home?.city &&
          programDocValue?.data?.checkoutQualification?.city !==
            userValue?.portfolios?.Student?.home?.city
        ) {
          flag = false;
          errorMessages.push(
            `Your must be from ${programDocValue?.data?.checkoutQualification?.city} city to checkout the program.`
          );
        }
        if (
          userValue?.userTypes?.includes('Student') &&
          userValue?.portfolios?.Student &&
          userValue?.portfolios?.Student?.school?.name &&
          programDocValue?.data?.checkoutQualification?.school &&
          programDocValue?.data?.checkoutQualification?.school !==
            userValue?.portfolios?.Student?.school?.name
        ) {
          flag = false;
          errorMessages.push(
            `Your must be from ${programDocValue?.data?.checkoutQualification?.school} school to checkout the program.`
          );
        }
        if (
          userValue?.userTypes?.includes('Student') &&
          userValue?.portfolios?.Student &&
          programDocValue?.data?.checkoutQualification?.schoolDistrict &&
          programDocValue?.data?.checkoutQualification?.schoolDistrict !==
            userValue?.portfolios?.Student?.school?.district
        ) {
          flag = false;
          errorMessages.push(
            `Your must be from ${programDocValue?.data?.checkoutQualification?.schoolDistrict} school district to checkout the program.`
          );
        }
      } else {
        flag = true;
      }
      checkoutQualificationErrors.value = [...errorMessages];
      return flag;
    };

    onMounted(async () => {
      if (programDoc.value?.data?.checkoutQualification?.roles?.length >= 1) {
        const permissionsValue = await getPermissionsByRole({
          table: 'Roles',
          roles: JSON.stringify(programDoc.value?.data?.checkoutQualification?.roles)
        });
        checkOutPermissions.value = permissionsValue;
      } else if (
        user?.value?.userTypes?.includes('Student') &&
        !permissions?.value?.includes('can_checkout_program') &&
        localStorage.getItem('apollo-token')
      ) {
        store.dispatch(`tools/${ActionTypes.showSnackbar}`, {
          message: "you don't have permission to checkout program",
          type: 'error',
          isShowSnackbar: true
        });
      }
      // check all checkout qualifications
      const val = isCheckOutPermission(
        [...permissions?.value, ...checkOutPermissions?.value],
        user?.value,
        programDoc?.value
      );
      isVisible.value = val;
    });

    const adk = computed(() => {
      return programDoc.value?.data?.adks?.find(a => a?.name === 'start');
    });

    const fieldIndex = computed(() => {
      return adk.value?.fields?.findIndex(f => f.name === 'm-checkoutForm');
    });

    const fieldValue = computed(() => {
      return adk.value?.fields?.find(f => f.name === 'm-checkoutForm')?.value;
    });

    const isExternShip = computed(() => {
      const offer = programDoc?.value?.data?.adks?.find(adk =>
        adk.fields?.find(field => field.name === 'm-offer-table')
      );
      // const offer = programDoc.value?.data.adks.find(a => a.name === 'offer' || a?.adk === 'offer');

      const offerSetup = offer?.fields?.find(f => f.model === 'externCredential').setup;

      if (offerSetup === 2) return true;
      return false;
    });

    const userBirthDate = computed({
      get: () => birthDate.value,
      set: newVal => {
        birthdate.value = newVal;
      }
    });

    watchEffect(() => {
      if (!isExternShip.value) {
        externShipCheckout.value = true;
      }
    });

    phoneNumber.value = user.value?.phoneNumber?.substring(2);

    const rewardSetup = computed(() => {
      return adk.value.fields?.find(f => f.model === 'rewards')?.setup?.rewards;
    });

    const skillsSetup = computed(() => {
      return adk.value.fields?.find(f => f.model === 'requiredSkills')?.setup?.requiredSkills;
    });

    const rules = ref({
      required: value => !!value || 'Required.',
      allSkillsSelected: value =>
        (value && value.length === skillsSetup.value.length) || 'Required.'
    });

    const residencySetup = computed(() => {
      return adk.value.fields?.find(f => f.model === 'requiredResidency')?.setup?.requiredResidency;
    });

    const phoneVerified = computed(() => {
      return !!phoneVerification.value?.phoneVerified || !!emailVerification.value?.emailVerified;
    });

    watchEffect(() => {
      if (useToken?.value && localStorage.getItem('setCondition')) {
        const payload: any = JSON.parse(localStorage.getItem('checkOutFormPayload'));
        if (payload?.rewards) {
          fieldValue.value.rewards = payload?.rewards;
        }
        if (payload?.accessSkills) {
          fieldValue.value.accessSkills = payload?.accessSkills;
        }
        if (payload?.studentLocation) {
          fieldValue.value.studentLocation = payload?.studentLocation;
        }
        localStorage.removeItem('checkOutFormPayload');
        localStorage.removeItem('setCondition');
        if (
          !props?.value?.data?.isProgramCheckout &&
          checkoutQualificationErrors?.value?.length < 1 &&
          programDoc.value?.data?.tokens !== 0
        ) {
          useToken.value.dialog = true;
        }
      }
    });

    async function updateAdk() {
      try {
        const adkIndex = props.value.data.adks.findIndex(a => a?.name === 'start');
        await updateAdkData({
          program_id: props.value.data._id,
          document_type: 'Student',
          data: props.value.data.adks[adkIndex]
        });
      } catch (error) {
        console.log(error);
      }
    }
    const isLoading = ref(false);
    const openCheckoutDialog = async () => {
      if (!user?.value) {
        localStorage.setItem('checkOutFormPayload', JSON.stringify(fieldValue.value));
        ctx.emit('open-auth-dialog');
      } else if (
        programDoc.value?.data?.tokens !== undefined &&
        programDoc.value?.data?.tokens !== null &&
        programDoc.value?.data?.tokens === 0
      ) {
        isLoading.value = true;
        await updateAdk();
        const res = await checkoutFreeProgram({
          program_id: props.value.data.program_id?.toString(),
          participant_id: user?.value?._id?.toString()
        });
        if (res) {
          await props.fetchProgram();
          store.dispatch(`tools/${ActionTypes.showSnackbar}`, {
            message: 'Program checkout successfully',
            type: 'success',
            isShowSnackbar: true
          });
          isLoading.value = false;
          ctx.emit('next-page');
        }
      } else {
        useToken.value.dialog = true;
      }
    };

    const redirectToPayment = () => {
      ctx.root.$router.push({ name: 'payment' });
    };

    return {
      phoneVerified,
      residencySetup,
      adk,
      phone,
      email,
      phoneNumber,
      skillsSetup,
      rewardSetup,
      isExternShip,
      fieldValue,
      fieldIndex,
      useToken,
      birthdate,
      phoneVerification,
      emailVerification,
      rules,
      checkoutFormCompleted,
      checkoutQualification,
      isSetting,
      msg,
      externDialog,
      user,
      formattedNumber,
      applyForSponsorshipValid: false,
      verifyNumberDialog,
      verificationCode,
      birthdayMenu: false,
      setupInstructions: ref({
        description: '',
        instructions: ['', '', '']
      }),
      showInstructions: ref(true),
      eligibilityPresets: null,
      eligibilitySearch: null,
      programDoc,
      userBirthDate,
      birthDate,
      openCheckoutDialog,
      permissions,
      checkOutPermissions,
      isCheckOutPermission,
      isLoading,
      checkoutQualificationErrors,
      tokenDescDialog,
      isVisible,
      redirectToPayment
    };
  }
});
