import axios from 'axios';

const getFormSchema = async () => {
  const API_ENDPOINT = process.env.VUE_APP_PROGRAM_SCHEMA_SERVICE;
  try {
    const res = await axios.get(`${API_ENDPOINT}/schema-components`);
    return res.data;
  } catch (error) {
    return error;
  }
};

export default getFormSchema;
